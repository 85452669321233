import React from 'react';
import { PortableText } from '@portabletext/react';

const ProgressionBlock = ({ title, textColor, backgroundColor, items }) => {
    const styles = {
        progressionCMS: {
            backgroundColor: backgroundColor.hex,
            color: textColor.hex,
            padding: '20px',
        },
        title: {
            textAlign: 'center',
            color: textColor.hex,
        },
        gridContainer: {
            display: 'grid',
            gap: '20px',
            width: '100%',
            gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
        },
        itemContainer: {
            textAlign: 'center',
            border: `1px solid ${textColor.hex}`,
            borderRadius: '10px',
            padding: '20px',
            backgroundColor: backgroundColor.hex,
        },
        container: {
            width: "90%",
            margin: "auto",
        },
        itemNumber: {
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            backgroundColor: textColor.hex,
            color: backgroundColor.hex,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto 10px auto',
        },
        separator: {
            border: `1px solid ${textColor.hex}`,
            width: '80%',
            margin: '20px auto',
        },
        textcontent: {
            textAlign: "left",
            fontSize: "16px",
            fontWeight: "500",
        },
    };

    const containerVariants = {
        hidden: { opacity: 0, scale: 0.8 },
        visible: { opacity: 1, scale: 1, transition: { duration: 1, staggerChildren: 0.3 } },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 1 } },
    };

    return (
        <div className="progressionCMS" style={styles.progressionCMS}>
            <h2 style={styles.title}>{title}</h2>
            <div style={styles.container}>
                <div
                    style={styles.gridContainer}
                    initial="hidden"
                    animate="visible"
                    variants={containerVariants}
                >
                    {items.map((item, index) => (
                        <div key={index} style={styles.itemContainer} variants={itemVariants}>
                            <div style={styles.itemNumber}>{index + 1}</div>
                            <h3 style={styles.title}>{item.title}</h3>
                            {/* <p style={styles.textcontent}></p> */}
                            <PortableText value={item.blockText} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProgressionBlock;
