import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFetchWithToken } from "../hooks/useFetchWithToken";
import './styles/Forms.css';
import {useConfirm} from "../Provider/ConfirmProvider";
import useTexts from "../hooks/useTexte";

const Forms = () => {
    const fetchWithToken = useFetchWithToken();
    const navigate = useNavigate();
    const [formModal, setFormModal] = useState({
        open: false,
        newFormName: '',
        selectedFormId: null,
    });
    const { t } = useTexts();

    const [forms, setForms] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const {requestConfirm} = useConfirm(); // Utiliser le hook pour obtenir la fonction de demande de confirmation

    const loadForms = useCallback(async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form`;
            const data = await fetchWithToken(apiUrl);
            setForms(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error loading forms:', error);
        }
    }, [fetchWithToken]);

    useEffect(() => {
        loadForms();
    }, []);

    const handleOpen = useCallback((formId) => {
        setFormModal({ open: true, newFormName: '', selectedFormId: formId });
    }, []);

    const handleClose = useCallback(() => {
        setFormModal({ open: false, newFormName: '', selectedFormId: null });
    }, []);

    const handleDuplicate = useCallback(async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form/${formModal.selectedFormId}/duplicate`;
            const body = { name: formModal.newFormName };
            await fetchWithToken(apiUrl, "POST", body);
            loadForms();
            handleClose();
        } catch (error) {
            console.error('Error duplicating form:', error);
        }
    }, [formModal, fetchWithToken, loadForms, handleClose]);

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }
    const handleDeleFom = (id) => {
        requestConfirm(t("DELETE_MY_FORM_WARNIBF"), async () => {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form/${id}/delete`;
            await fetchWithToken(apiUrl, "POST",);
            loadForms();
        });
    };
    return (
        <Container maxWidth="md">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h1>Liste des questionnaires utilisateurs</h1>
                    <div className='formList'>
                        {forms.length > 0 ? (
                            forms.map((form) => (
                                <div className='formDetails'>
                                    <span id='formName'>{form.name}</span>
                                    <Box className='button-box'>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => handleOpen(form.id)}
                                            sx={{ marginRight: '10px' }}
                                        >
                                            Dupliquer
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => navigate(`/creer-template/${form.id}`)}
                                        >
                                            Modifier
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={() => handleDeleFom(form.id)}
                                        >
                                            {t("DELETE_FORM")}
                                        </Button>
                                    </Box>
                                </div>
                            ))
                        ) : (
                            <p>Aucun questionnaire à afficher.</p>
                        )}
                    </div>
                    <Button
                        onClick={() => navigate('/creer-template')}
                        variant="contained"
                        color="success"
                    >
                        Ajouter un questionnaire
                    </Button>
                </Grid>
            </Grid>

            <Modal
                open={formModal.open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="modal-title" variant="h6" component="h2">
                        Dupliquer le formulaire
                    </Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Nom du nouveau formulaire"
                        type="text"
                        fullWidth
                        value={formModal.newFormName}
                        onChange={(e) => setFormModal((prev) => ({ ...prev, newFormName: e.target.value }))}
                    />
                    <Button onClick={handleDuplicate} variant="contained" color="primary" sx={{ mt: 2 }}>
                        Dupliquer
                    </Button>
                </Box>
            </Modal>
        </Container>
    );
};

export default Forms;
