import React, { useEffect, useState } from 'react';
import {
    Button,
    Typography,
    Box,
    Grid,
    CircularProgress,
} from '@mui/material';
import { useFetchWithToken } from "../../hooks/useFetchWithToken";
import { useSystem } from "../../Context/System";
import useTexts from "../../hooks/useTexte";
import { useAuth } from "../../Context/AuthContext";
import MyFicheSection from "../MyFicheSection";
import { useParams } from "react-router-dom";
import CMSTop from "../CMSTop";
import client from "../../sanityClient";

function QuestionairePostFormation({ id, onClose }) {
    const { token, currentFunction, functionOptions } = useAuth();
    const chefFunction = functionOptions.find(func => func.id == currentFunction);
    const fetchWithToken = useFetchWithToken();
    const { getConfiguration } = useSystem();
    const [isLoadingDetail, setIsLoadingDetail] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const { t } = useTexts();
    const [formData, setFormData] = useState();
    const [formState, setFormState] = useState({});
    const { link, user_id, formation_id } = useParams();
    const [isSubmitted, setIsSubmitted] = useState(false); // Nouvel état pour soumission
    const [cmsData, setCmsData] = useState(false)
    useEffect(() => {
        if (token) {

const codepage = "/avis-formation"

                    client.fetch(`
          *[_type == "editorialPage" && codepage == $codepage]{
            title,
            content,
               imageHero,
                    description,
            list[]{
              image,
              text,
              link->{
                _id,
                title
              }
            }
          }
        `, {codepage}).then((data) => {
                        // Traiter les données récupérées et les stocker dans un état local si nécessaire
                        setCmsData(data[0]); // Supposons que vous obtenez une liste et que vous prenez le premier élément

                    });
                }

    }, []);
    const loadWishesDetail = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form/getformeval/${link}/${user_id}/${formation_id}`;
            const data = await fetchWithToken(apiUrl);

            const newForm = { "sections": [] };
            for (const key in data.data) {
                const section = data.data[key];
                newForm.sections.push(section);
            }
            setFormData(newForm);
            setFormState(data.answer || {});
            setIsLoadingDetail(false);
        } catch (error) {
            setHasError(true);
            setIsLoadingDetail(false);
        }
    };

    useEffect(() => {
        loadWishesDetail();
    }, []);



    const allFieldsFilled = () => {
        return Object.keys(formData.sections).every(sectionIndex =>
            formData.sections[sectionIndex].fields.every(field => {
                // Ignorer les champs de type "text" ou "textarea"
                if (field.type === "textarea" || field.type === "text") {
                    return true; // Champs facultatifs, considérés comme remplis
                }
                // Vérifier si le champ est présent dans formState
                return formState.hasOwnProperty(`${sectionIndex}_${field.name}`);
            })
        );
    };

    const handleCheckboxChange = (sectionIndex, fieldName, optionValue, isChecked, isMultiple) => {
        if (isMultiple) {
            setFormState(prevState => {
                const existingValues = prevState[`${sectionIndex}_${fieldName}`] || [];
                return {
                    ...prevState,
                    [`${sectionIndex}_${fieldName}`]: isChecked ? [...existingValues, optionValue] : existingValues.filter(value => value !== optionValue)
                };
            });
        } else {
            setFormState(prevState => ({
                ...prevState,
                [`${sectionIndex}_${fieldName}`]: isChecked
            }));
        }
    };

    const handleInputChange = (sectionIndex, fieldName, value) => {
        setFormState(prevState => ({
            ...prevState,
            [`${sectionIndex}_${fieldName}`]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!allFieldsFilled()) {
            alert(t('PLEASE_FILL_ALL_FIELDS'));
            return;
        }

        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form/getformeval/${link}/${user_id}/${formation_id}/save`;
            const body = { formData: formState };
            await fetchWithToken(apiUrl, "POST", body);
            setIsSubmitted(true); // Marquer comme soumis pour masquer le formulaire
        } catch (error) {
            console.error('Erreur lors de la soumission du formulaire:', error);
        }
    };

    if (hasError) {
        return (
            <Box sx={{ textAlign: 'center', p: 3 }}>
                <Typography variant="h6" color="error">{t('PAGE_NOT_FOUND')}</Typography>
                <Typography>{t('SORRY_PAGE_NOT_AVAILABLE')}</Typography>
            </Box>
        );
    }

    if (!formData || isLoadingDetail) {
        return <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}><CircularProgress /></Box>;
    }

    if (isSubmitted) {
        return (
            <Box sx={{ textAlign: 'center', p: 3 }}>
                <Typography variant="h6" color="primary">{t('THANK_YOU')}</Typography>
                <Typography>{t('YOUR_RESPONSE_HAS_BEEN_SAVED')}</Typography>
            </Box>
        );
    }

    const sectionFilter = formData.sections.filter(section => {
        if (Array.isArray(section.rules) && section.rules.length > 0) {
            return section.rules.includes(chefFunction.fonction);
        }
        return true;
    });

    return (


        <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off" id='ficheFormContent'>
            {(cmsData && token) && (
                <CMSTop cmsData={cmsData}/>
            )}
            <Grid spacing={3}>
                <MyFicheSection formData={formData} formState={formState}
                                handleInputChange={handleInputChange}
                                handleCheckboxChange={handleCheckboxChange}
                                chefFunction={chefFunction}
                                isEval={1}
                />
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }} className="actions-button">
                <Button type="submit" variant="contained" color='success' sx={{ mr: 2 }}>{t('VALIDATE_POST_QUESTIONAIRE')}</Button>
            </Box>
        </Box>
    );
}

export default QuestionairePostFormation;
