import React from 'react';
import { Autocomplete, TextField, FormControl } from '@mui/material';
import useTexts from "../hooks/useTexte";

function StructureAutocomplete({ data, selectedData, handleChange, label }) {
    const { t } = useTexts();

    // Transformation des options pour correspondre au format attendu par Autocomplete
    const options = data.map((option) => ({
        id: option.id,
        label: `${option.id_sycomore} ${option.nom}`,
    }));
    console.log(options)
    console.log(selectedData)
    // Trouver les options sélectionnées actuelles pour l'affichage
    const selectedOptions = options.filter((option) => selectedData.includes(option.id));
    console.log(selectedOptions)

    return (
        <FormControl variant="outlined" fullWidth sx={{ minWidth: 120, flex: '1 1 auto' }}>
            <Autocomplete
                multiple // Permettre plusieurs sélections
                options={options}
                getOptionLabel={(option) => option.label}
                value={selectedOptions}
                onChange={(event, newValue) => handleChange(newValue.map((item) => item.id))}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        placeholder={t("SEARCH")}
                    />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                noOptionsText={t("NO_OPTIONS")}
                clearOnEscape
            />
        </FormControl>
    );
}

export default StructureAutocomplete;
