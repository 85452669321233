import React from 'react';
import { Typography } from "@mui/material";
import useTexts from "../hooks/useTexte";
import { LevelOptions } from "../Utils/LevelOption";
import { branchOptions } from "../Utils/BranchOption";
import { formatDate } from "../Utils/functionTransforms";

const AgendaItem = ({ formation }) => {
    const level = LevelOptions.find((branch) => formation.level === branch.key);
    const branch = branchOptions.find((branch) => formation.code === branch.key);
    const { t } = useTexts();

    return (
        <div className='agenda-event-item'>
            {/* Formation title */}
            <div className='item-infos'>
                {branch && branch.logo && (
                    <img src={branch.logo} alt="Training" style={{ width: '50px', height: '50px' }} />
                )}
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginRight: '8px' }}>
                    {level.label} - {branch.label}
                </Typography>
            </div>

            {/* Location */}
            <Typography variant="body2" sx={{ flexGrow: 1, marginRight: '8px' }}>
                {formation.lieu}
            </Typography>

            {/* Date */}
            <div id='date-from-to'>
                <p>Du <span id='chip'>{formatDate(formation.start_date)}</span></p>
                <p>au <span id='chip'>{formatDate(formation.end_date)}</span></p>
            </div>

        </div>
    );
};

export default AgendaItem;
