import React, { useEffect, useState } from 'react';
import {
    Button,
    Container,
    Pagination,
    Collapse,
    IconButton,
    Select,
    MenuItem,
    InputLabel,
    FormControl, Grid
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFetchWithToken } from '../hooks/useFetchWithToken';
import WishItem from '../composant/Wish/WishItem';
import formatFirstName from '../Utils/formatFirstName';
import { branchOptions } from "../Utils/BranchOption";
import { LevelOptions } from "../Utils/LevelOption";
import StructureAutocomplete from "../composant/StructureAutocomplete";
import useTexts from "../hooks/useTexte";
import { useAuth } from "../Context/AuthContext";
import { useSearchParams } from "react-router-dom";
import './styles/VoeuxListPage.css';

const VoeuxListPage = ({ onSelect }) => {
    const fetchWithToken = useFetchWithToken();
    const [usersWithWishes, setUsersWithWishes] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();


    const [selectedBranche, setSelectedBranche] = useState(searchParams.get('branche') || '');
    const [selectedStruct, setSelectedStruct] = useState(
        searchParams.get('structure')
            ? searchParams.get('structure').split(',').map(Number) // Conversion en entier
            : []
    );
    const [selectedLevel, setSelectedLevel] = useState(
        LevelOptions.find(option => option.key === searchParams.get('level')) || ''
    );
    const [page, setPage] = useState(Number(searchParams.get('page')) || 1);




    const [totalPages, setTotalPages] = useState(0);
    // const [page, setPage] = useState(1);
    const [expandedUser, setExpandedUser] = useState({});
    const { isLoadingFunction, childUnite, currentFunction } = useAuth();
    const { t } = useTexts();

    const handleExpandClick = (userId) => {
        setExpandedUser((prevExpanded) => ({
            ...prevExpanded,
            [userId]: !prevExpanded[userId]
        }));
    };
    const handleChangeStruct = (value) => {
        setSelectedStruct(value);
        setPage(1);

    };
    const toggleCollapseAll = () => {
        const allExpanded = Object.values(expandedUser).every((value) => value);
        const newExpandedUser = Object.keys(expandedUser).reduce((acc, key) => {
            acc[key] = !allExpanded;
            return acc;
        }, {});
        setExpandedUser(newExpandedUser);
    };
    useEffect(() => {
        const params = {

        };
        if (selectedBranche) {
            params['branche'] = selectedBranche
        }
        if (selectedStruct && selectedStruct.length > 0) {

            params['structure'] = selectedStruct.join(',')
        }
        if (selectedLevel) {
            params['level'] = selectedLevel.key
        }
        if (page && page > 1) {
            params['page'] = page
        }
        setSearchParams(params);
    }, [selectedBranche, selectedStruct, selectedLevel, page]);

    const loadWishes = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/geetmystructWish`;
            const body = {
                branche: selectedBranche,
                level: selectedLevel ? selectedLevel.key : "",
                structure: selectedStruct,
                page
            };
            const data = await fetchWithToken(apiUrl, 'POST', body);
            setUsersWithWishes(data.users);
            setTotalPages(data.totalPages);

            // default expand all
            const initialExpandedUser = data.users.reduce((acc, user) => {
                acc[user.user.user_id] = true;
                return acc;
            }, {});
            setExpandedUser(initialExpandedUser);
        } catch (error) {
            console.error('Erreur lors du chargement des vœux:', error);
            setUsersWithWishes([]);
        }
    };

    useEffect(() => {
        loadWishes();
    }, [page, selectedBranche, selectedStruct, selectedLevel]);

    const formatLastName = (lastName) => {
        return lastName.toUpperCase();
    };
    const formatFunction = (structName, func_name) => {
        return structName + " " + func_name
    };


    return (
        <Container maxWidth="xl" className='suivi-inscription-container'>
            <div className='filters-container'>
                <div id='branches'>
                    <FormControl fullWidth>
                        <InputLabel id="branches">Branches</InputLabel>
                        <Select
                            labelId="branches"
                            id="branches"
                            value={selectedBranche}
                            label="Branches"
                            onChange={(e) => setSelectedBranche(e.target.value)}
                        >
                            <MenuItem value="">Toutes les branches</MenuItem>

                            {branchOptions.map((option) => (
                                <MenuItem key={option.key} value={option.key}>
                                    {option.label}
                                </MenuItem>
                            ))}


                        </Select>
                    </FormControl>
                </div>

                <div id='levels'>
                    <FormControl fullWidth>
                        <InputLabel id="levels">Levels</InputLabel>
                        <Select
                            labelId="levels"
                            id="levels"
                            value={selectedLevel}
                            label="Levels"
                            onChange={(e) => setSelectedLevel(e.target.value)}
                        >
                            <MenuItem value="">Tous les niveaux</MenuItem>
                            {LevelOptions.map((option) => (
                                <MenuItem key={option.key} value={option}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div id='structures'>
                    <StructureAutocomplete data={childUnite} selectedData={selectedStruct}
                        handleChange={handleChangeStruct}
                        label={t("LIST_FORMATION_STRUCT")}
                    />
                </div>
            </div>
            <Pagination
                count={totalPages}
                page={page}
                onChange={(event, value) => setPage(value)}
                className="pagination-container"
                style={{ margin: '20px 0', display: 'flex', justifyContent: 'center' }}
            />

            <div className='wishesbyUser'>
                {usersWithWishes && usersWithWishes.map((userWithWish) => (
                    <div key={userWithWish.user.user_id} className='user-wishes'>
                        <div className='user-wishes-header'>
                            <h3>
                                {formatFirstName(userWithWish.user.firstname)} {formatLastName(userWithWish.user.lastname)}{" - "}
                                {formatFunction(userWithWish.user.structure_nom, userWithWish.user.fonction)}
                            </h3>
                            <IconButton
                                aria-label="expand"
                                onClick={() => handleExpandClick(userWithWish.user.user_id)}
                                sx={{
                                    transform: expandedUser[userWithWish.user.user_id] ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </div>
                        <Collapse in={expandedUser[userWithWish.user.user_id]} timeout="auto" unmountOnExit>
                            <div className='user-collapse'>
                                {userWithWish.formations.map((wish) => (
                                    <WishItem key={wish.voeu_id} wish={wish} user={userWithWish.user} type="formation" />
                                ))}
                                {userWithWish.wishes.map((wish) => (
                                    <WishItem key={wish.voeu_id} wish={wish} user={userWithWish.user} type="voeu" />
                                ))}
                            </div>
                        </Collapse>
                    </div>
                ))}
            </div>

        </Container>
    );
};

export default VoeuxListPage;
