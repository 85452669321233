import React, { useEffect, useState } from 'react';
import {
    Button,
    Typography,
    Select,
    MenuItem,
    TextField,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Modal,
} from '@mui/material';

import useTexts from "../../hooks/useTexte";
import { cepBranchType } from "../../Utils/CepBranchType";
import AnimationSuccess from '../Core/AnimationSuccess/AnimationSuccess';

const MoulinetteModal = ({ openMoulinetteDialog, setOpenMoulinetteDialog, handleLaunchMoulinette, launchAnimation, setLaunchAnimation }) => {
    const [moulinettePlaces, setMoulinettePlaces] = useState(0);
    const [selectionType, setSelectionType] = useState('');
    const [functionCodes, setFunctionCodes] = useState([""]); // Pour les codes fonction
    const [selectedSpecialities, setSelectedSpecialities] = useState([]); // Pour les spécialités

    const [showRestrictions, setShowRestrictions] = useState(false);

    const handleFunctionCodeChange = (value, index) => {
        const newCodes = [...functionCodes];
        newCodes[index] = value;
        setFunctionCodes(newCodes);
    };
    const toggleRestrictions = () => {
        if (showRestrictions) {
            // Réinitialise les valeurs des filtres lorsqu'on cache les restrictions
            setFunctionCodes(['']);
            setSelectedSpecialities([]);
        }
        setShowRestrictions(!showRestrictions);
    };
    const removeFunctionCodeField = (index) => {
        const newCodes = [...functionCodes];
        newCodes.splice(index, 1);
        setFunctionCodes(newCodes);
    };

    const addFunctionCodeField = () => {
        setFunctionCodes([...functionCodes, ""]);
    };
    const { t } = useTexts()
    useEffect(() => {
        setMoulinettePlaces(0)
        setFunctionCodes(['']);
        setSelectedSpecialities([]);
        setSelectionType('')
        setShowRestrictions(false)
    }, []);

    const closeModalAfterAnimation = () => {
        setTimeout(() => {
            setLaunchAnimation(false);
            setOpenMoulinetteDialog(false);
        }, 2000); // Adjust the timeout duration to match your animation length
    };

    return (
        <Dialog fullWidth open={openMoulinetteDialog} onClose={() => setOpenMoulinetteDialog(false)}>
            <DialogTitle textAlign={'center'} color={'#29224f'}>{t("BUTTON_START_MOULINETTE")}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="moulinettePlaces"
                    label={t("FIELD_NBR_PLACE_MOULINETTE")}
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={moulinettePlaces || 0}
                    onChange={(e) => setMoulinettePlaces(e.target.value)}
                />

                <Select
                    fullWidth
                    value={selectionType}
                    displayEmpty // Ajout pour afficher un texte par défaut quand rien n'est sélectionné

                    onChange={(e) => setSelectionType(e.target.value)}
                    variant="outlined"
                    margin="dense"
                >
                    <MenuItem value="">Sélectionner un type</MenuItem>
                    <MenuItem value="selection">{t("MOULINETTE_SECTION")}</MenuItem>
                    <MenuItem value="selectionAttribution">{t("MOULINETTE_ATTRIBUTION")}</MenuItem>
                </Select>

                {/* Bouton pour afficher/cacher les restrictions */}
                <Button onClick={toggleRestrictions} color="primary" style={{ marginTop: 16 }}>
                    {showRestrictions ? t("HIDE_RESTRICTIONS") : t("SHOW_RESTRICTIONS")}
                </Button>

                {showRestrictions && (
                    <div style={{ marginTop: 16 }}>
                        {/* Ajout des codes fonction */}
                        <Typography variant="subtitle1">{t("MOULINETTE_FUNCTION_CODES")}</Typography>
                        {functionCodes.map((code, index) => (
                            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                                <TextField
                                    margin="dense"
                                    label={t("MOULINETTE_FUNCTION_CODE", { number: index + 1 })}
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    value={code}
                                    onChange={(e) => handleFunctionCodeChange(e.target.value, index)}
                                />
                                <Button onClick={() => removeFunctionCodeField(index)} color="secondary" style={{ marginLeft: 8 }}>
                                    {t("REMOVE")}
                                </Button>
                            </div>
                        ))}
                        <Button onClick={addFunctionCodeField} color="primary" variant="contained" style={{ marginTop: 8 }}>
                            {t("MOULINETTE_ADD_FUNCTION_CODE")}
                        </Button>

                        {/* Sélection des spécialités */}
                        <Typography variant="subtitle1" style={{ marginTop: 16 }}>{t("MOULINETTE_SELECT_SPECIALITY")}</Typography>
                        <Select
                            fullWidth
                            multiple
                            value={selectedSpecialities}
                            onChange={(e) => setSelectedSpecialities(e.target.value)}
                            variant="outlined"
                            margin="dense"
                            renderValue={(selected) => selected.map(value => cepBranchType[value]).join(', ')}
                        >
                            <MenuItem value="">
                                <em>{t("NO_SPECIALITY")}</em>
                            </MenuItem>
                            {Object.keys(cepBranchType).map((key) => (
                                <MenuItem key={key} value={key}>
                                    {cepBranchType[key]}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenMoulinetteDialog(false)} color="primary">
                    Annuler
                </Button>
                <Button
                    onClick={() => {
                        handleLaunchMoulinette(moulinettePlaces, selectionType, selectedSpecialities, functionCodes);
                        setLaunchAnimation(true);
                        closeModalAfterAnimation();
                    }}
                    color="primary"
                >
                    Lancer
                </Button>
            </DialogActions>
            <Modal open={launchAnimation} onClose={() => setLaunchAnimation(false)}>
                <AnimationSuccess />
            </Modal>
        </Dialog>
    )
};

export default MoulinetteModal;
