import React, { useState, useEffect, useRef } from 'react';
import { useFetchWithToken } from "../hooks/useFetchWithToken";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
    Container,
    Typography,
    List,
    ListItem,
    ListItemText,
    TextField,
    Button,
    Paper,
    Box,
    Divider,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    IconButton,
    Modal
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Snackbar from '@mui/material/Snackbar';

import withAdminRole from "../hoc/withAdminRole";

const EmailTemplateEditor = () => {
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [focusedField, setFocusedField] = useState('template');
    const fetchWithToken = useFetchWithToken();
    const quillRef = useRef(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false); // snackbar is like a toast message !! I like toasts

    useEffect(() => {
        const fetchTemplates = async () => {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/email-templates`;
            const response = await fetchWithToken(apiUrl, "POST");
            setTemplates(response);
        };

        fetchTemplates();
    }, []);
    const handleTemplateChange = (e) => {
        const { name, value } = e.target;
        setSelectedTemplate((prevTemplate) => ({
            ...prevTemplate,
            [name]: value,
        }));
    };


    const handleQuillChange = (content, delta, source, editor) => {
        setSelectedTemplate((prevTemplate) => ({
            ...prevTemplate,
            template: editor.getHTML(),
        }));
    };

    const handleTemplateSelect = (template) => {
        setSelectedTemplate(template);
        setFocusedField('template'); // Reset focus to the template editor by default
    };

    const handleSave = async () => {
        const { id, template, subject, vars , description} = selectedTemplate;
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/email-templates/${id}`;
        await fetchWithToken(apiUrl, "POST", { template, subject, description });
        setSelectedTemplate(null);
        setSnackbarOpen(true);
        await handleUpdateTemplates();
    };

    const handleUpdateTemplates = async () => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/email-templates`;
        const response = await fetchWithToken(apiUrl, "POST");
        setTemplates(response);
    };

    const handleVariableInsert = (variableCode) => {
        if (selectedTemplate) {
            if (focusedField === 'template') {
                const quill = quillRef.current.getEditor(); // Obtenir l'instance de quill
                const selection = quill.getSelection();
                if (selection) {
                    const cursorPosition = selection.index;
                    quill.insertText(cursorPosition, `{{${variableCode}}}`);
                    quill.setSelection(cursorPosition + variableCode.length + 4); // Déplacer le curseur après le texte inséré
                }
            } else if (focusedField === 'subject') {
                const subjectField = document.getElementById('subject-field');
                const cursorPosition = subjectField.selectionStart;
                const text = selectedTemplate.subject;
                const newText = text.slice(0, cursorPosition) + `{{${variableCode}}}` + text.slice(cursorPosition);
                setSelectedTemplate((prevTemplate) => ({
                    ...prevTemplate,
                    subject: newText
                }));
            }
        }
    };

    return (
        <Container className='email-editor-container'>
            <Typography variant="h4" component="h1" gutterBottom>
                Éditeur de Notifications Email
            </Typography>
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="space-between" mt={4} className='email-editor'>
                <Box width={{ xs: '100%' }} mr={{ md: 2 }} mb={{ xs: 4, md: 0 }}>
                    <Paper elevation={3} sx={{ p: 2 }}>
                        <Typography variant="h6" component="h2" gutterBottom>
                            Sélectionner un Modèle
                        </Typography>
                        <List>
                            {templates.map((template) => (
                                <ListItem button key={template.id} onClick={() => handleTemplateSelect(template)}>
                                    <ListItemText primary={template.description} />
                                    <IconButton edge="end" aria-label="edit">
                                        <EditIcon />
                                    </IconButton>
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </Box>
                <Modal
                    open={selectedTemplate !== null}
                    onClose={() => setSelectedTemplate(null)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '80%',
                            maxHeight: '95vh', // Set maximum height
                            overflowY: 'auto', // Enable scrolling if content exceeds max height
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                        }}
                    >
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Éditer le Modèle
                        </Typography>
                        <Divider />
                        <Box mt={2}>
                            <TextField
                                id="subject-field"
                                label="Objet"
                                name="subject"
                                value={selectedTemplate?.subject}
                                onChange={handleTemplateChange}
                                onFocus={() => setFocusedField('subject')}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                id="description-field"
                                label="Description"
                                name="description"
                                value={selectedTemplate?.description}
                                onChange={handleTemplateChange}
                                onFocus={() => setFocusedField('description')}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                            />
                            <ReactQuill
                                ref={quillRef}
                                value={selectedTemplate?.template}
                                onChange={handleQuillChange}
                                onFocus={() => setFocusedField('template')}
                                style={{ height: '400px', marginBottom: '50px' }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ size: [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                            { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link', 'image'],
                                        ['clean'],
                                        [{ 'insertVar': 'variable' }]
                                    ]
                                }}
                            />
                            <Box mt={2} display="flex" flexDirection="column">
                                <FormControl variant="outlined" margin="normal" fullWidth>
                                    <InputLabel id="variable-select-label">Insérer une Variable</InputLabel>
                                    <Select
                                        labelId="variable-select-label"
                                        onChange={(e) => handleVariableInsert(e.target.value)}
                                        label="Insérer une Variable"
                                    >
                                        {selectedTemplate?.vars.map((variable) => (
                                            <MenuItem key={variable.code} value={variable.code}>
                                                {variable.libelle}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box mt={2} display="flex" justifyContent="flex-end" gap={'8px'}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => setSelectedTemplate(null)}
                                    startIcon={<CancelIcon />}
                                    sx={{ textTransform: 'none' }}
                                >
                                    Annuler
                                </Button>
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={handleSave}
                                    startIcon={<SaveIcon />}
                                    sx={{ textTransform: 'none' }}
                                >
                                    Enregistrer
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={3000}
                    onClose={() => setSnackbarOpen(false)}
                    message="Modifications enregistrées avec succès"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                />

            </Box>
        </Container>
    );
};

export default withAdminRole(EmailTemplateEditor);

