import React, {useEffect, useState} from 'react';
import {
    Grid,
    Container,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress,
    Box,
    Pagination, DialogTitle, DialogContent, TextField, IconButton, Button, Dialog, DialogActions,
    Typography,
     FormControlLabel, Switch,
} from '@mui/material';
import {useAuth} from '../Context/AuthContext';
import {useSystem} from '../Context/System';
import {branchOptions} from '../Utils/BranchOption';
import {LevelOptions} from '../Utils/LevelOption';
import {useFetchWithToken} from "../hooks/useFetchWithToken"
import FormationAdminItemTable from "../composant/Formation/FormationAdminItemTable";
import useTexts from "../hooks/useTexte";
import withAdminOrFormationRole from "../hoc/withAdminOrFormationRole";
import useRoles from "../hooks/useRoles";
import './styles/FormationAdminListPage.css';
import {Add, Remove} from "@mui/icons-material";

const FormationAdminListPage = () => {
    const {isLoadingFunction, functionOptions, section, currentFunction} = useAuth();
    const [selectedType, setSelectedType] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const {t} = useTexts();
    const [selectedBranche, setSelectedBranche] = useState('');
    const [wishes, setWishes] = useState([]);
    const fetchWithToken = useFetchWithToken();
    const [showClose, setShowClose] = useState(false);
    const [showOpen, setShowOpen] = useState(true);

    const chefFunction = functionOptions.find(func => func.id == currentFunction);
    const roles = useRoles(chefFunction ? chefFunction.fonction : "");
    const [openModal, setOpenModal] = useState(false);
    const [trainingDates, setTrainingDates] = useState([{ date: '', persons: 0 }]);
    const [selectedBranch, setSelectedBranch] = useState('');
    const [selectedLevel, setSelectedLevel] = useState('');
    const isAdministrator = roles.includes('Administrator');

    const [questionnaires, setQuestionnaires] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(1);

    const {datesGroupe} = useSystem();
    const handleChangeShowClose = (value) => {
        setShowClose(value);
        setPage(1);
    };
    const handleChangeShowOpen = (value) => {
        setShowOpen(value);
        setPage(1);
    };
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const handleBranchChange = (event) => {
        setSelectedBranch(event.target.value);
    };
    const handleAddDate = () => {
        setTrainingDates([...trainingDates, { date: '', persons: 0 }]);
    };
    const handleRemoveDate = (index) => {
        const newDates = [...trainingDates];
        newDates.splice(index, 1);
        setTrainingDates(newDates);
    };

    const handleDateChange = (index, value) => {
        const newDates = [...trainingDates];
        newDates[index].date = value;
        setTrainingDates(newDates);
    };

    const handlePersonsChange = (index, value) => {
        const newDates = [...trainingDates];
        newDates[index].persons = value;
        setTrainingDates(newDates);
    };

    const handleChangeDate = (event) => {
        setSelectedDate(event.target.value);
        setPage(1);
    };
    const handleChangeType = (event) => {
        setSelectedType(event.target.value);
        setPage(1);
    };

    const addFormation = async (wish) => {
        try {
            let apiUrl = `${process.env.REACT_APP_API_URL}/api/formation`;
            const data = await fetchWithToken(apiUrl, 'POST', wish);
            loadWishes();
            return data;
        } catch (error) {
            console.error('Erreur lors de l\'ajout de la formation:', error);
        }
    };

    const fetchQuestionnaires = async () => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/form`;
        const data = await fetchWithToken(apiUrl);
        setQuestionnaires(data);
        setLoading(false);
    };
    const handleChangeBranche = (event) => {
        setSelectedBranche(event.target.value);
        setPage(1);
    };
    const testOpeningDates = async () => {
        try {

            const body = {
                dates:trainingDates,
                selectedBranch,
                selectedLevel

            }
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/fomrationsadmin/testDate`;


            const blob = await fetchWithToken(apiUrl, "POST", body, false, "BLOB");


            // Téléchargement du fichier PDF
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'simulation.xlsx');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Erreur lors du chargement des formations:', error);
        }
    };

    const loadWishes = async () => {
        try {
            const body = {
                branche: selectedBranche,
                date: selectedDate,
                level: selectedType.key,
                showClose,
                showOpen,
                page
            }
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin`;
            const data = await fetchWithToken(apiUrl, 'POST', body);
            setWishes(data.formations);
            setTotalPages(data.totalPages);
        } catch (error) {
            console.error('Erreur lors du chargement des formations:', error);
        }
    };

    useEffect(() => {

        fetchQuestionnaires();
        loadWishes();

    }, []);

    useEffect(() => {
        loadWishes();
    }, [selectedType, selectedBranche, page, selectedDate, showClose, showOpen ]);


    if (!isLoadingFunction || loading) {
        return <Box sx={{display: 'flex', justifyContent: 'center', p: 3}}>
            <CircularProgress />
        </Box>;
    }

    return (
        <Container
            maxWidth="xl"
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Button variant="contained" onClick={handleOpenModal} sx={{ mb: 2 }}>
                Tester Jour de Formation
            </Button>

            <Dialog open={openModal} onClose={handleCloseModal} maxWidth="sm" fullWidth>
                <DialogTitle>Tester Jour de Formation</DialogTitle>
                <DialogContent>
                    <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                        <InputLabel>Choix de la Branche</InputLabel>
                        <Select
                            value={selectedBranch}
                            onChange={handleBranchChange}
                            label="Choix de la Branche"
                        >
                            {branchOptions.map((option, index) => (
                                <MenuItem key={index} value={option.key}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                        <InputLabel>Choix du Niveau</InputLabel>
                        <Select
                            value={selectedLevel}
                            onChange={(event) => setSelectedLevel(event.target.value)}
                            label="Choix du Niveau"
                            disabled={!selectedBranch} // Désactiver tant qu'aucune branche n'est sélectionnée
                        >
                            {LevelOptions.filter(option => option.section.includes(selectedBranch)).map((option, index) => (
                                <MenuItem key={index} value={option.key}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {trainingDates.map((item, index) => (
                        <Box key={index} display="flex" alignItems="center" sx={{ mb: 2 }}>
                            <TextField
                                type="date"
                                label="Date"
                                value={item.date}
                                onChange={(e) => handleDateChange(index, e.target.value)}
                                fullWidth
                                sx={{ mr: 1 }}
                            />
                            <TextField
                                type="number"
                                label="Nombre de Personnes"
                                value={item.persons}
                                onChange={(e) => handlePersonsChange(index, e.target.value)}
                                sx={{ width: 150, mr: 1 }}
                            />
                            <IconButton onClick={() => handleAddDate()} color="primary">
                                <Add />
                            </IconButton>
                            {index > 0 && (
                                <IconButton onClick={() => handleRemoveDate(index)} color="secondary">
                                    <Remove />
                                </IconButton>
                            )}
                        </Box>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Annuler</Button>
                    <Button onClick={() => { testOpeningDates() }}>Ajouter</Button>
                </DialogActions>
            </Dialog>
            <Typography variant='h4' gutterBottom>{t('TITRE_PAGE_GERER_CEPS')}</Typography>
            <Container maxWidth="xl" sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 240 }}>
                    <InputLabel>{t("TEXT_TYPE_FORMATION")}</InputLabel>
                    <Select
                        value={selectedType} onChange={handleChangeType} label={t("TEXT_TYPE_FORMATION")}
                    >
                        <MenuItem value="">
                            <em>{t("INPUT_SELECT_ALL")}</em>
                        </MenuItem>
                        {LevelOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {isAdministrator && (
                    <FormControl variant="outlined" sx={{ m: 1, minWidth: 240 }}>
                        <InputLabel>{t("TEXT_COL_BRANCHE")}</InputLabel>
                        <Select
                            value={selectedBranche}
                            onChange={handleChangeBranche}
                            label={t("TEXT_COL_BRANCHE")}
                        >
                            <MenuItem value="">
                                <em>{t("INPUT_SELECT_ALL")}</em>
                            </MenuItem>
                            {branchOptions.map((option) => (
                                <MenuItem key={option.key} value={option.key}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 240 }}>
                    <InputLabel>Dates</InputLabel>
                    <Select
                        value={selectedDate} onChange={handleChangeDate} label={t("TEXT_TYPE_FORMATION")}
                    >
                        <MenuItem value="">
                            <em>{t("INPUT_SELECT_ALL")}</em>
                        </MenuItem>
                        {datesGroupe.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.nom}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Container>
            <div className='pagination-container'>
                <Pagination count={totalPages} page={page} onChange={(event, value) => setPage(value)} />
            </div>
            <Box className='cep-container'>
                {wishes.map((wish, index) => (
                    <FormationAdminItemTable
                        key={index}
                        wish={wish}
                        index={index}
                        questionnaires={questionnaires}
                        functionOptions={functionOptions}
                        addFormation={addFormation}
                        loadWishes={loadWishes}
                    />
                ))}
            </Box>
            <div className='pagination-container'>
                <Pagination count={totalPages} page={page} onChange={(event, value) => setPage(value)} />
            </div>
        </Container>
    );
};

export default withAdminOrFormationRole(FormationAdminListPage);
