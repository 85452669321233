import React from 'react';
import HeaderLogo from "./HeaderLogo";
import HeaderProfile from "./HeaderProfile";
import Navbar from "./Navbar/Navbar";
import './styles/Header.css';
import { useMediaQuery, useTheme } from '@mui/material';

const Header = () => {
    const theme = useTheme();
    const mediaquery = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <>
            {!mediaquery ? (
                <div className="upper-header">
                    <HeaderLogo />
                    <HeaderProfile />
                </div>
            ) : null}
            <Navbar />
        </>
    );
};

export default Header;
