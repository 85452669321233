export  function transformFunctionOption(option) {
    // Extraire le numéro d'unité à partir du nom
    try {
        let prefixe ;
        let strcuct_name = "";
        if (option.structure_nom){
            prefixe = option.structure_nom.split(' ')[0]; // Extrait le code de début du nom
            strcuct_name = option.structure_nom;
        }else{
            prefixe = option.nom.split(' ')[0]; // Extrait le code de début du nom
            strcuct_name = option.nom;

        }
        const fonctionTransformee = transformFunctionOptionPart(option)
        return `${strcuct_name} - ${fonctionTransformee}`;
    }catch (e) {
        return  "Erreur"
    }
}


export  function transformFunctionOptionPart(option) {
    // Extraire le numéro d'unité à partir du nom
    try {
        let prefixe ;
        let strcuct_name = "";
        if (option.structure_nom){
            prefixe = option.structure_nom.split(' ')[0]; // Extrait le code de début du nom
            strcuct_name = option.structure_nom;
        }else{
            prefixe = option.nom.split(' ')[0]; // Extrait le code de début du nom
            strcuct_name = option.nom;

        }
        let fonctionTransformee = option.fonction;

        const mapping = {
            'TPE': {
                'CH': 'Chef de troupe',
                'ASSCH': 'Assistant chef de troupe'
            },
            'CIE': {
                'CH': 'Cheftaine de compagnie',
                'ASSCH': 'Assistant cheftaine de compagnie'
            },
            'CLAIR': {
                'CH': 'Akela',
                'ASSCH': 'Assistant cheftaine de meute'
            },
            'MTE': {
                'CH': 'Akela',
                'ASSCH': 'Louvetier'
            },
            'DIST': {
                'ASCOM': 'Assistant du commisaire de district',
                'ASCBJ': 'Assistant du commisaire de district louvetisme',
                'ASCBV': 'Assistant du commisaire de district eclaireur',
                'ASCBR': 'Assistant du commisaire de district ainée',
                'COM': 'Commissaire de district'
            },

        };
        if (mapping[prefixe]) {
            const base = mapping[prefixe].base || '';
            const specifics = mapping[prefixe];
            fonctionTransformee = specifics[option.fonction];
        }

        return `${fonctionTransformee}`;
    }catch (e) {
        return  ""
    }
}

export function formatDate(dateString){
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('fr-FR', options);
}

export function formatDateHour(dateString) {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    };
    return new Date(dateString).toLocaleDateString('fr-FR', options);
}
export function  calculateEndDate (startDateString, branche, level){
    let diffDay = 7;

    if (branche == "territorial"){
        diffDay = 4;
    }
    const startDate = new Date(startDateString);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + diffDay);
    return formatDate(endDate);
}