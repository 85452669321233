import React, { useEffect, useState } from 'react';
import {
    Button,
    Grid,
    Container,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Toolbar,
    AppBar,
    Box,
    CircularProgress,
    TextField,
    Checkbox,
    CardContent,
    Card, DialogTitle, DialogContent, Dialog, Typography, Pagination, IconButton, Collapse
} from '@mui/material';
import { useFetchWithToken } from '../hooks/useFetchWithToken';
import { branchOptions } from '../Utils/BranchOption';
import { LevelOptions } from '../Utils/LevelOption';
import ErrorModal from "../composant/Modal/ErrorModal";
import ValidateModal from "../composant/Modal/ValidateModal";
import useTexts from "../hooks/useTexte";
import ConfirmeModal from "../composant/Modal/ConfirmeModal";
import { style } from "../Utils/style";
import { useAuth } from "../Context/AuthContext";
import DerogationItem from "../composant/Wish/DerogationItem";
import withAdminRole from "../hoc/withAdminRole";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import SearchModal from "../composant/Search/SearchModal";
import { useSystem } from "../Context/System";
import StructureAutocomplete from "../composant/StructureAutocomplete";
import { DatePicker } from "@mui/x-date-pickers";
import WishItem from "../composant/Wish/WishItem";
import formatFirstName from "../Utils/formatFirstName";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UserListPage from "./UserListPage";

const AdminVoeuxListPage = ({ onSelect }) => {
    const [open, setOpen] = useState(false);
    const [selectedPersonne, setSselectedPersonne] = useState(false);
    const [selectedType, setSelectedType] = useState('');
    const [selectedBranche, setSelectedBranche] = useState('');
    const [modalOpenError, setModalOpenError] = useState(false);
    const [modalErrorMessage, setModalErrorMessage] = useState("");
    const [modalOpenValidate, setModalOpenValidate] = useState(false);
    const [modalValidateMessage, setModalValidateMessage] = useState("");
    const [modalOpenValidateRemove, setModalOpenValidateRemove] = useState(false);
    const [modalOpenValidateRemoveId, setModalOpenValidateRemoveId] = useState(0);
    const [dialogOpenSearch, setDialogOpenSearch] = useState(0);
    const [selectedstartDate, setSelectedStartDate] = useState(null);
    const [selectedendDate, setSelectedEndDate] = useState(null);
    const { provinces, districts } = useSystem(); // Utilisation du contexte pour obtenir les provinces
    const [selectedProvince, setSelectedProvince] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [usersWithWishes, setUsersWithWishes] = useState([]);
    const [selectedCodeSycomore, setSelectedCodeSycomore] = useState('');

    const [dialogOpen, setDialogOpen] = useState(false);

    const [selectedWishes, setSelectedWishes] = useState([]);
    const [wishes, setWishes] = useState([]);
    const [comment, setComment] = useState('');
    const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(1);
    const fetchWithToken = useFetchWithToken();
    const { t } = useTexts();
    const { isLoadingFunction, section } = useAuth();

    const handleCloseUserModal = () => {
        setDialogOpen(false);
        setSelectedCodeSycomore('');
    };


    const handleOpenUserModal = (codeSycomore) => {
        setSelectedCodeSycomore(codeSycomore);
        setDialogOpen(true);
    };

    const handleChangeType = (event) => {
        setSelectedType(event.target.value);
        setPage(1);

    };
    const handleChangeDistrict = (value) => {
        setSelectedDistrict(value);
        setPage(1);
    };

    const handleChangeDate2 = (event) => {
        setSelectedEndDate(event);
        setPage(1);
    };
    const handleChangeDate1 = (event) => {
        setSelectedStartDate(event);
        setPage(1);
    };
    const handleChangeProvince = (value) => {
        setSelectedProvince(value);
        setPage(1);

    };
    const handleChangeBranche = (event) => {
        setSelectedBranche(event.target.value);
        setPage(1);

    };

    const handleCloseRemove = (event) => {
        setModalOpenValidateRemove(false);
        setModalOpenValidateRemoveId(0);
    };



    const handleCloseModalSucces = () => {
        setModalOpenValidate(false);
        setModalValidateMessage(null);
    };
    const loadWishes = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/wishes/all`;

            const body = {
                branche: selectedBranche,
                province: selectedProvince,
                district: selectedDistrict,
                startDate: selectedstartDate,
                endDate: selectedendDate,
                level: selectedType.key,
                user: (selectedPersonne ? selectedPersonne.id : null),
                page
            };
            const data = await fetchWithToken(apiUrl, 'POST', body);
            setUsersWithWishes(data.users);
            setTotalPages(data.totalPages);

            // default expand all
            const initialExpandedUser = data.users.reduce((acc, user) => {
                acc[user.user.user_id] = true;
                return acc;
            }, {});
        } catch (error) {
            console.error('Erreur lors du chargement des vœux:', error);
            setUsersWithWishes([]);
        }
    };

    useEffect(() => {
        loadWishes();
    }, [selectedType, selectedBranche, page, selectedPersonne, selectedProvince, selectedDistrict, selectedstartDate, selectedendDate]);

    const HandlePersonneSearch = (newuser, function_id) => {
        // Handle add trainee logic
        setSselectedPersonne(newuser)
        setDialogOpenSearch(false);
    };


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const nextPage = () => {
        setPage(page + 1);
    };
    const handleResetPersonne = () => {
        setSselectedPersonne(null);
        setDialogOpenSearch(false)
    };
    const prevPage = () => {
        if (page > 1) setPage(page - 1);
    };
    const handleOpenSearchDialog = () => {
        setDialogOpenSearch(true);
    };
    const formatSearchUser = (user) => {
        return user.firstname + " " + user.lastname
    };

    const formatLastName = (lastName) => {
        return lastName.toUpperCase();
    };
    const formatFunction = (structName, func_name) => {
        return structName + " " + func_name
    };




    if (!isLoadingFunction) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }


    return (
        <Container maxWidth="xl">
            <AppBar position="static" color="default" sx={{ top: 10, boxShadow: "none", background: "none" }}>
                <div className='filters-container'>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>{t("TEXT_COL_TYPE")}</InputLabel>
                            <Select
                                value={selectedType}
                                onChange={handleChangeType}
                                label={t("TEXT_COL_TYPE")}
                            >
                                <MenuItem value="">
                                    <em>{t("INPUT_SELECT_ALL")}</em>
                                </MenuItem>
                                {LevelOptions.map((option) => (
                                    <MenuItem key={option.key} value={option}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>{t("LIST_FORMATION_BRANCHE")}</InputLabel>
                            <Select
                                value={selectedBranche}
                                onChange={handleChangeBranche}
                                label="Branche"
                            >
                                <MenuItem value="">
                                    <em>{t("INPUT_SELECT_ALL")}</em>
                                </MenuItem>
                                {branchOptions.map((option) => (
                                    <MenuItem key={option.key} value={option.key}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <StructureAutocomplete data={districts} selectedData={selectedDistrict} handleChange={handleChangeDistrict}
                        label={t("LIST_FORMATION_DISTRICT")}
                    />
                    <StructureAutocomplete data={provinces} selectedData={selectedProvince} handleChange={handleChangeProvince}
                        label={t("LIST_FORMATION_PROVINCE")}
                    />
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl variant="outlined" fullWidth>

                            <DatePicker
                                label="Date de début"
                                value={selectedstartDate}
                                onChange={handleChangeDate1}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl variant="outlined" fullWidth>

                            <DatePicker
                                label="Date de fin"
                                value={selectedendDate}
                                onChange={handleChangeDate2}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </FormControl>
                    </Grid>
                    <div id='filter-item'>
                        <TextField
                            label="Nom de l'utilisateur"
                            value={(selectedPersonne ? formatSearchUser(selectedPersonne) : "")}
                            InputProps={{
                                readOnly: true,
                            }}
                            onClick={handleOpenSearchDialog}
                            variant="outlined"
                            sx={{ flex: 1 }}
                            fullWidth
                        />
                    </div>
                </div>
            </AppBar>
            <Dialog open={dialogOpenSearch} onClose={() => setDialogOpenSearch(false)} aria-labelledby="search-dialog-title">



                <DialogTitle id="search-dialog-title">{t("FIELD_MESTRE_CAMP")}</DialogTitle>
                <DialogContent>
                    <SearchModal onClick={HandlePersonneSearch} functionMode={0} />
                    <Button variant="contained" color="secondary" onClick={handleResetPersonne}>
                        Réinitialiser
                    </Button>
                </DialogContent>
            </Dialog>

            <Pagination
                count={totalPages}
                page={page}
                onChange={(event, value) => setPage(value)}
                className="pagination-container"
                style={{ margin: '20px 0', display: 'flex', justifyContent: 'center' }}
            />
            <Grid container spacing={2} className='wishesbyUser'>
                {usersWithWishes && usersWithWishes.map((userWithWish) => (
                    <Grid item xs={12} key={userWithWish.user.user_id} className='user-wishes'>
                        <div className='user-wishes-header'>
                            <p>
                                {formatFirstName(userWithWish.user.firstname)} {formatLastName(userWithWish.user.lastname)}{" - "}
                                {formatFunction(userWithWish.user.structure_nom, userWithWish.user.fonction)}
                            </p>
                            <a id='voir-fiche' onClick={() => handleOpenUserModal(userWithWish.user.user_code_sycomore)}>
                                Voir la fiche
                            </a>
                        </div>
                        <Collapse in={1} timeout="auto" unmountOnExit>

                            {userWithWish.formations.map((wish) => (
                                <WishItem key={wish.voeu_id} wish={wish} user={userWithWish.user} type="formation" />
                            ))}

                            {userWithWish.wishes.map((wish) => (
                                <WishItem key={wish.voeu_id} wish={wish} user={userWithWish.user} type="voeu" />
                            ))}

                        </Collapse>
                    </Grid>
                ))}
            </Grid>

            <Dialog
                open={dialogOpen}
                onClose={handleCloseUserModal}
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle>Informations Utilisateur</DialogTitle>
                <DialogContent>
                    <UserListPage codeSycomoreData={selectedCodeSycomore} />
                </DialogContent>
                <Button onClick={handleCloseUserModal}>Fermer</Button>
            </Dialog>
        </Container>
    );
};
export default withAdminRole(AdminVoeuxListPage);
