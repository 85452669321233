import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/DownloadRounded';

import './styles/DocumentCard.css';

const DocumentCard = ({ document, onDelete, onDownload }) => {

    // Converti les accents: Ã©claireuses
    function documentNameAccent(nom) {
        let newNom = nom;
        let accent = {
            "Ã©": "é",
        };
        for (let key in accent) {
            newNom = newNom.replace(key, accent[key]);
        }
        return newNom;
    }

    return (
        <Card className='document-card-container'>
            <CardContent id='document-card-content'>
                <div id='document-infos'>
                    <Typography sx={{ fontFamily: 'Ubuntu', fontSize: "20px" }} variant="h5">
                        {documentNameAccent(document.nom)}
                    </Typography>
                </div>
                <div id='document-actions'>
                    <IconButton onClick={onDownload}><DownloadIcon /></IconButton>
                    {onDelete && <IconButton onClick={onDelete}><DeleteIcon color="error" /></IconButton>}
                </div>
            </CardContent>
        </Card>
    );
};

export default DocumentCard;
