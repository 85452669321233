import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {useFetchWithToken} from "../hooks/useFetchWithToken";
import './styles/Forms.css';
import useTexts from "../hooks/useTexte";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import CustomFormBuilder from "../composant/customFormBuilder/CustomFormBuilder";
import {useConfirm} from "../Provider/ConfirmProvider";
import PreviewModal from "../composant/customFormBuilder/PreviewModal";

const MyForms = () => {
    const fetchWithToken = useFetchWithToken();
    const navigate = useNavigate();
    const [openFormModal, setOpenFormModal] = useState(false);
    const [hasUnsavedChangesFiche, setHasUnsavedChangesFiche] = useState(false);
    const {requestConfirm} = useConfirm(); // Utiliser le hook pour obtenir la fonction de demande de confirmation
    const [initialFormData, setInitialFormData] = useState(null);
    const [templates, setTemplates] = useState([]);
    const [openTemplateModal, setOpenTemplateModal] = useState(false);
    const [duplicateFormTemplateId, setDuplicateFormTemplateId] = useState(false);
    const [modalDuplicateTemplate, setModalDuplicateTemplate] = useState(false);
    const [duplicateFromTemplateNom, setDuplicateFromTemplateNom] = useState(false);

    const handleOpenTemplateModal = () => setOpenTemplateModal(true);
    const handleCloseTemplateModal = () => setOpenTemplateModal(false);
    const [formModal, setFormModal] = useState({
        open: false,
        newFormName: '',
        selectedFormId: null,
    });

    const handleCloseFormModal = () => {
        if (hasUnsavedChangesFiche) {
            // Demander la confirmation si des modifications non sauvegardées sont présentes
            requestConfirm(t("MODIFICATION_LOST_WARNING"), () => {
                setOpenFormModal(false);
            });
        } else {

            setOpenFormModal(false);
        }
        loadForms()
        setHasUnsavedChangesFiche(false)
    };


    const [forms, setForms] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const {t} = useTexts();
    const loadForms = useCallback(async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/myform`;
            const data = await fetchWithToken(apiUrl);
            setForms(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error loading forms:', error);
        }
    }, [fetchWithToken]);

    useEffect(() => {
        loadForms();
        loadTemplates();
    }, []);
    const handleOpenFormModal = (id) => {
        if (!id) {
            setInitialFormData(false);
        } else {
            setInitialFormData(id);
        }
        setOpenFormModal(true);
    };

    const handleDeleFom = (id) => {
        requestConfirm(t("DELETE_MY_FORM_WARNIBF"), async () => {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/myform/${id}/delete`;
            await fetchWithToken(apiUrl, "POST",);
            loadForms();
        });
    };
    const [previewOpen, setPreviewOpen] = useState(false);
    const [sections, setSections] = useState([]);
    const [formName, setFormName] = useState('');

    const closePreview = () => setPreviewOpen(false);

    const handleOpen = useCallback((formId) => {
        setFormModal({open: true, newFormName: '', selectedFormId: formId});
    }, []);




    const handleCloseTemplateClose= async () => {
        setDuplicateFormTemplateId(false)
        setModalDuplicateTemplate(false)
        setDuplicateFromTemplateNom(false)
    };

    const duplicateTemplate= async (templateId) => {
        try {
            const body = {
                name:duplicateFromTemplateNom
            }
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/myform/duplicateFromtemplate/${modalDuplicateTemplate}`;
            await fetchWithToken(apiUrl, "POST", body);
            loadForms(); // Recharger la liste des formulaires après ajout
            handleCloseTemplateClose();
            setOpenTemplateModal(false)
        } catch (error) {
            console.error('Error adding form from template:', error);
        }
    };
    const handleAddFromTemplate = async (templateId) => {
        setDuplicateFormTemplateId(true)
        setModalDuplicateTemplate(templateId)

    };
    const handleClose = useCallback(() => {
        setFormModal({open: false, newFormName: '', selectedFormId: null});
    }, []);
    const loadTemplates = useCallback(async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form/templates`;
            const data = await fetchWithToken(apiUrl);
            setTemplates(data);
        } catch (error) {
            console.error('Error loading templates:', error);
        }
    }, [fetchWithToken]);
    const handleDuplicate = useCallback(async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/myform/${formModal.selectedFormId}/duplicate`;
            const body = {name: formModal.newFormName};
            await fetchWithToken(apiUrl, "POST", body);
            loadForms();
            handleClose();
        } catch (error) {
            console.error('Error duplicating form:', error);
        }
    }, [formModal, fetchWithToken, loadForms, handleClose]);

    if (isLoading) {
        return (
            <Box sx={{display: 'flex', justifyContent: 'center', p: 3}}>
                <CircularProgress/>
            </Box>
        );
    }
    const loadFormSections = async (id) => {
        try {

            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form/template/${id}`;
            const data = await fetchWithToken(apiUrl);
            const newForm = data.data.map((section) => ({
                ...section,
                fields: section.fields || [],
            }));
            setSections(newForm);
            setFormName(data.name);
            setPreviewOpen(true)
        } catch (error) {
            console.error('Erreur lors du chargement des sections:', error);
        } finally {
            // collapse les sections par défaut
            setSections((prevSections) => prevSections.map((section) => ({...section, expanded: false})));
       }
    };
    return (
        <Container maxWidth="md">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h1>{t("MY_TEMPLATES")}</h1>
                    <div className='formList'>
                        {forms.length > 0 ? (
                            forms.map((form) => (
                                <div className='formDetails'>
                                    <span id='formName'>{form.name}</span>
                                    <Box className='button-box'>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => handleOpen(form.id)}
                                            sx={{marginRight: '10px'}}
                                        >
                                            {t("DUPLIQUER_MY_FORM")}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleOpenFormModal(form.id)}
                                        >
                                            {t("MODIFIER_MY_FORM")}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={() => handleDeleFom(form.id)}
                                        >
                                            {t("DELETE_MY_FORM")}
                                        </Button>
                                    </Box>
                                </div>
                            ))
                        ) : (
                            <p>{t("NO_MY_TEMPLATES")}</p>
                        )}
                    </div>
                    <Button
                        onClick={() => handleOpenFormModal()}
                        variant="contained"
                        color="success"
                    >
                        {t("ADD_NEW_TEMPLATES")}
                    </Button>

                    <Button
                        onClick={handleOpenTemplateModal}
                        variant="contained"
                        color="primary"
                        sx={{marginTop: '20px'}}
                    >
                        {t("ADD_QUESTIONNAIRE_FROM_TEMPLATE")}
                    </Button>
                </Grid>
            </Grid>
            <Modal
                open={openTemplateModal}
                onClose={handleCloseTemplateModal}
                aria-labelledby="template-modal-title"
                aria-describedby="template-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        maxHeight: '80vh',
                        overflowY: 'auto',
                    }}
                >
                    <Typography id="template-modal-title" variant="h6" component="h2">
                        {t("TEMPLATES_LIST")}
                    </Typography>
                    {templates.length > 0 ? (
                        templates.map((template) => (
                            <Box key={template.id} sx={{marginBottom: 2}}>
                                <Typography variant="subtitle1">{template.name}</Typography>
                                <Box>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleAddFromTemplate(template.id)}
                                        sx={{marginRight: '10px'}}
                                    >
                                        {t("ADD_FROM_TEMPLATE")}
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => loadFormSections(template.id)}
                                    >
                                        {t("PREVIEW_TEMPLATE")}
                                    </Button>
                                </Box>
                            </Box>
                        ))
                    ) : (
                        <Typography>{t("NO_TEMPLATES_AVAILABLE")}</Typography>
                    )}
                </Box>
            </Modal>
            <Modal
                open={formModal.open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="modal-title" variant="h6" component="h2">
                        {t("DUPLIQUER_MY_FORM")}
                    </Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Nom du nouveau formulaire"
                        type="text"
                        fullWidth
                        value={formModal.newFormName}
                        onChange={(e) => setFormModal((prev) => ({...prev, newFormName: e.target.value}))}
                    />
                    <Button onClick={handleDuplicate} variant="contained" color="primary" sx={{mt: 2}}>
                        {t("DUPLIQUER_MY_FORM")}
                    </Button>
                </Box>
            </Modal>

            <Modal
                open={modalDuplicateTemplate}
                onClose={handleCloseTemplateClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="modal-title" variant="h6" component="h2">
                        {t("DUPLIQUER_FORM")}
                    </Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Nom du nouveau formulaire"
                        type="text"
                        fullWidth
                        value={duplicateFromTemplateNom || ""}
                        onChange={(e) => setDuplicateFromTemplateNom( e.target.value)}
                    />
                    <Button onClick={duplicateTemplate} variant="contained" color="primary" sx={{mt: 2}}>
                        {t("DUPLIQUER_FORM")}
                    </Button>
                </Box>
            </Modal>
            <PreviewModal
                open={previewOpen}
                onClose={closePreview}
                sections={sections}
            />
            <Dialog open={openFormModal} onClose={handleCloseFormModal} maxWidth="lg" fullWidth>
                <DialogTitle> {initialFormData === false ? (t("ADD_NEW_TEMPLATE ")) : (t("UPDATE_TEMPLATE "))} </DialogTitle>
                <DialogContent>
                    <CustomFormBuilder initialData={initialFormData} isModal={true}
                                       closeModal={handleCloseFormModal}
                                       user={true}
                                       setHasUnsavedChangesParent={setHasUnsavedChangesFiche}/>
                </DialogContent>
            </Dialog>
        </Container>
    );
};

export default MyForms;
