import React from 'react';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from "react-router-dom";

function NavbarBlock({ item, setIsNavCollapsed, setOpenDropdown }) {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleNavigation = () => {
        if (item.onClick) {
            item.onClick();
        } else {
            navigate(item.to);
            if (setIsNavCollapsed && setOpenDropdown) {
                setIsNavCollapsed(true);
                setOpenDropdown(null);
            }
        }
    };

    if (isMobile && item.mobile === false) {
        return null;
    }
    return (
        <p
            // className={`dropdown dropdownMenu ${isOpen ? 'open' : ''}`}
            onClick={handleNavigation}
            style={{ cursor: 'pointer' }}
        >
            {item.text}
        </p>
    );
}

export default NavbarBlock;
