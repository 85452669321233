import React, {useEffect, useState} from 'react';
import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent, Box, DialogActions,
    Skeleton
} from '@mui/material';
import {useFetchWithToken} from "../../hooks/useFetchWithToken";
import FormFieldView from "../Fiche/FormFieldView";
import {makeStyles} from "tss-react/mui";
import './styles/StagiaireCard.css';
import DocumentCard from "../Core/DocumentCard";
import {downloadDocument} from "../../Utils/downloadDocument";
import {useAuth} from "../../Context/AuthContext";
import './styles/StagiaireCard.css';

const useStyles = makeStyles((theme) => ({
    listItem: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    listItemText: {
        fontWeight: 'bold',
    },
    buttonWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        marginRight: theme.spacing(2),
    },
    dialogContent: {
        padding: theme.spacing(2),
    },
}));
const StagiareCard = ({user, attachments}) => {
    const [imageUrl, setImageUrl] = useState('');
    const fetchWithToken = useFetchWithToken();
    const classes = useStyles();
    const [avis, setAvis] = useState([]);
    const [documents, setDocuments] = useState(false);
    const [openDocumentsModal, setOpenDocumentsModal] = useState(false);
    const {token,} = useAuth()
    const [avisLastFormation, setAvisLastFormation] = useState(false);
    const [path, setPath] = useState('');
    const [formData, setFormData] = useState();
    const [formState, setFormState] = useState({});
    const [fileNames, setFileNames] = useState({}); // Nouvel état pour stocker les noms des fichiers
    const [loadingPdf, setLoadingPdf] = useState(false);
    const dataImage = {
        "formation_id": user.formation_id,
        "auth": "mestre",
    }

    const loadWishesDetail = async () => {
        try {
            const id = 0;
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${user.formation_user_id}/${user.formation_id}/answer`;
            const data = await fetchWithToken(apiUrl);

            var newForm = {
                "sections": []
            }
            let key;
            for (key in data.data) {
                const section = data.data[key]
                newForm.sections.push(section)
            }
            setFormData(newForm);
            setAvis(data.avis)
            setAvisLastFormation(data.formation_user)
            setFormState(data.answer)

            for (key in data.answer) {
                const answer = data.answer[key]
                const splits = key.split("_")
                const id_section = splits[0];
                const key_input = splits[1];

                if (newForm.sections[id_section] && newForm.sections[id_section].fields) {
                    let input_map = newForm.sections[id_section].fields.filter(function (f) {
                        return f.name == key_input
                    });
                    if (input_map.length > 0) {
                        input_map = input_map[0];
                        if (input_map.type == "file") {
                            const nameFile = answer.name
                            setFormState(prevState => ({
                                ...prevState,
                                [`${id_section}_${key_input}`]: {content: answer.content, name: nameFile}
                            }));
                            setFileNames(prevState => ({
                                ...prevState,
                                [`${id_section}_${key_input}`]: nameFile // Stocker le nom du fichier dans l'état
                            }));
                        }

                    }

                }

            }

            setOpenfiche(true)
        } catch (error) {
            console.error('Erreur lors du chargement des vœux:', error);
        }
    };
    const handleExportPDF = async () => {
        setLoadingPdf(true);
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${user.formation_user_id}/${user.formation_id}/answer?format=pdf`;
            const response = await fetchWithToken(apiUrl, "POST", formData, false, "RESPONSE");

            // Vérifier si la réponse est correcte
            if (!response.ok) {
                throw new Error('Erreur lors de la récupération du fichier.');
            }

            // Récupérer le blob depuis la réponse
            const blob = await response.blob();

            // Récupérer l'en-tête Content-Disposition
            const contentDisposition = response.headers.get('Content-Disposition');
            console.log(response.headers)
            // Extraire le nom de fichier depuis l'en-tête (si présent)
            let fileName = ''; // Nom par défaut
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);


                if (fileNameMatch.length > 1) {
                    fileName = fileNameMatch[1];
                }
            }
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            setLoadingPdf(false);
        } catch (error) {
            console.error('Erreur lors de l\'export en PDF :', error);
            setLoadingPdf(false);
        }
    };

    const [openFiche, setOpenfiche] = useState(false)

    // if (photoAttachment.)
    const fetchImage = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/images/${path}/80/80`;
            const imageData = await fetchWithToken(apiUrl, "POST", dataImage, false, "TEXT");
            setImageUrl(imageData);
            console.log(apiUrl)
        } catch (error) {
            console.error('Erreur lors de la récupération de l\'image :', error);
        }
    };
    const handleCloseDocumentsModal = () => {
        setOpenDocumentsModal(false);
    };
    useEffect(() => {

        if (documents){
            setOpenDocumentsModal(true)

        }
    }, [documents]);

    async function loadDocuments() {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${user.formation_id}/${user.formation_user_id}/documents`;
            const data = await fetchWithToken(apiUrl, "POST");
            setDocuments(data);
        } catch (error) {
            console.error('Erreur lors de la récupération de l\'image :', error);
        }
    }
    const handleDownloadDocument = (filename) => {
        downloadDocument(filename, token, {"type": "mestre2", "formationId": user.formation_id})

    };

    useEffect(() => {
        if (attachments) {
            const photoAttachment = attachments.find(attachment => attachment.type === 'photo');
            if (photoAttachment) {
                setPath(photoAttachment.path)
            }
        }
    }, []); // Re-run effect when path or authToken changes

    useEffect(() => {
        fetchImage();
        // Clean up function to revoke the object URL to avoid memory leaks
        return () => URL.revokeObjectURL(imageUrl);
    }, [path]); // Re-run effect when path or authToken changes
    const avisChef = avis.find(avis => avis.avis_level === 1);
    const avisCEP = avis.find(avis => avis.avis_level === 1);

    const avisChef2 = avis.find(avis => avis.avis_level === 2);
    const avisPeda = avis.find(avis => avis.avis_is_peda === 1);

    return (
        <>
            <Card id="stagiaire-card-item">
                {imageUrl ? (
                    <CardMedia
                        sx={{ width: '80px', height: '80px', margin: '-25px auto 10px', zIndex: 1, position: 'relative' }}
                        component="img"
                        height='150px'
                        width='150px'
                        image={imageUrl || ""}
                        alt="photo"
                        style={{ maxWidth: '150px', maxHeight: '150px' }}
                    />
                ) : (
                    <Skeleton variant="rectangular" height={150} width={150} />
                )}
                <CardContent id='stagiaire-card-content'>
                    <Typography variant="h6">
                        {user.user_code_sycomore ? `${user.user_code_sycomore} - ` : ""}{user.user_firstname} {user.user_lastname}
                    </Typography>
                    <div id='buttons'>
                        <Button variant="outlined" color="primary" onClick={(e) => loadWishesDetail()}>
                            Voir la fiche
                        </Button>
                        {loadingPdf ? (
                            <Typography variant="body1" color="textSecondary">
                                Export en cours
                            </Typography>
                        ) : (
                            <Button variant="outlined" color="primary" onClick={handleExportPDF}>
                                Exporter en PDF
                            </Button>
                        )}
                    </div>
                </CardContent>
            </Card>
            <Dialog open={openDocumentsModal} onClose={handleCloseDocumentsModal} maxWidth="lg" fullWidth>
                <DialogTitle>Documents</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {documents && documents.map((doc) => (
                            <Grid item xs={12} sm={6} md={4} key={doc.id}>
                                <DocumentCard document={doc}
                                              onDownload={() => handleDownloadDocument(doc.path)}/>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog open={openFiche} onClose={() => setOpenfiche(false)} maxWidth="lg" fullWidth>
                <DialogTitle>Fiche stagiaire</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    {formData &&
                        formData.sections &&
                        formData.sections.map((section, sectionIndex) => (
                            <Box key={sectionIndex} mb={2}>
                                <Typography variant="h6">{section.title}</Typography>
                                <Grid container spacing={2}>
                                    {section.fields.map((field, fieldIndex) => (
                                        <Grid item xs={12} key={fieldIndex}>
                                            <FormFieldView
                                                field={field}
                                                sectionIndex={sectionIndex}
                                                formState={formState}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        ))}
                    <Box mt={4}>
                        <Typography variant="h6">Avis</Typography>
                        {avisChef ? (
                                <Box mt={2}>
                                    <Typography variant="subtitle1">N+1</Typography>
                                    <Typography variant="body1">
                                        {avisChef.idontknow === 1 ? 'Je ne connais pas le chef' : avisChef.avis_avis}
                                    </Typography>
                                </Box>
                            )
                            :
                            <Box mt={2}>
                                <Typography variant="subtitle1">Avis du N+1</Typography>
                                <Typography variant="body1">Pas d'avis</Typography>
                            </Box>
                        }
                        {avisChef2 ? (
                                <Box mt={2}>
                                    <Typography variant="subtitle1">N+2</Typography>
                                    <Typography variant="body1">
                                        {avisChef2.idontknow === 1 ? 'Je ne connais pas le chef' : avisChef2.avis_avis}
                                    </Typography>
                                </Box>
                            )
                            :
                            <Box mt={2}>
                                <Typography variant="subtitle1">Avis du N+1</Typography>
                                <Typography variant="body1">Pas d'avis</Typography>
                            </Box>
                        }
                        {avisPeda ? (
                                <Box mt={2}>
                                    <Typography variant="subtitle1">Avis Assistant de Branche</Typography>
                                    <Typography variant="body1">
                                        {avisPeda.idontknow === 1 ? 'Je ne connais pas le chef' : avisPeda.avis_avis}
                                    </Typography>
                                </Box>
                            ) :
                            <Box mt={2}>
                                <Typography variant="subtitle1">Avis Assistant de Branche</Typography>
                                <Typography variant="body1">Pas d'avis</Typography>
                            </Box>
                        }

                        {avisLastFormation && (
                                <Box mt={2}>
                                    <Typography variant="subtitle1">Avis formateur</Typography>
                                    <Typography variant="body1">
                                        {avisLastFormation.Avis}
                                    </Typography>
                                </Box>
                            )
                        }

                    </Box>
                </DialogContent>
                <DialogActions>
                    {loadingPdf ? (
                        <Typography variant="body1" color="textSecondary">
                            Export en cours
                        </Typography>
                    ) : (
                        <Button onClick={handleExportPDF} color="primary">
                            Exporter en PDF
                        </Button>
                    )}
                    <Button onClick={() => setOpenfiche(false)} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default StagiareCard;
