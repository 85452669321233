import React from 'react';
import { Card, CardContent, Typography, Divider, List, ListItemText, ListItemButton } from '@mui/material';
import ClickIcon from '@mui/icons-material/TouchApp'; // Assurez-vous d'avoir importé cette icône depuis @mui/icons-material
import './styles/Search.css';

const SearchResult = ({ person, index, handleClick, functionMode }) => {
    return (
        <>
            <Card
                key={index}
                onClick={(e) => {
                    if (handleClick && !functionMode) { handleClick(person) };
                }}
                className='search-result-card'
            >
                <CardContent>
                    <Typography variant="h6" component="div" style={{ display: 'flex', alignItems: 'center' }}>
                        <ClickIcon style={{ marginRight: 8 }} />
                        {person.lastname} - {person.firstname}
                    </Typography>

                    <Divider style={{ margin: '10px 0' }} />
                    <Typography variant="body1">
                        Code: {person.code_sycomore}
                    </Typography>
                    {functionMode === 1 && person.fonctions.length > 0 && (
                        <List>
                            {person.fonctions.map((fonction, index) => (
                                <ListItemButton key={index} onClick={() => handleClick(person, fonction.id)}>
                                    <ListItemText primary={`${fonction.fonction} - ${fonction.structure_name}`} />
                                </ListItemButton>
                            ))}
                        </List>
                    )}
                </CardContent>
            </Card>
        </>
    );
};

export default SearchResult;
