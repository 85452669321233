import React, { useEffect, useState } from 'react';
import {
    Button,
    Grid,
    Container,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Toolbar,
    AppBar,
    Box,
    CircularProgress,
    TextField,
    Checkbox,
    CardContent,
    Card, DialogTitle, DialogContent, Dialog,
    Pagination
} from '@mui/material';
import { useFetchWithToken } from '../hooks/useFetchWithToken';
import { branchOptions } from '../Utils/BranchOption';
import { LevelOptions } from '../Utils/LevelOption';

import useTexts from "../hooks/useTexte";
import ConfirmeModal from "../composant/Modal/ConfirmeModal";
import { style } from "../Utils/style";
import { useAuth } from "../Context/AuthContext";
import DerogationItem from "../composant/Wish/DerogationItem";

import SearchModal from "../composant/Search/SearchModal";
import withAdminOrFormationRole from "../hoc/withAdminOrFormationRole";
import {DatePicker} from "@mui/x-date-pickers";

const DerogationListTable = ({ onSelect }) => {
    const [open, setOpen] = useState(false);
    const [selectedPersonne, setSselectedPersonne] = useState(false);
    const [selectedType, setSelectedType] = useState('');
    const [selectedBranche, setSelectedBranche] = useState('');
    const [selectedstartDate, setSelectedStartDate] = useState(null);
    const [selectedendDate, setSelectedEndDate] = useState(null);

    const [modalOpenValidate, setModalOpenValidate] = useState(false);
    const [modalOpenValidateRemove, setModalOpenValidateRemove] = useState(false);
    const [dialogOpenSearch, setDialogOpenSearch] = useState(0);

    const [selectedWishes, setSelectedWishes] = useState([]);
    const [wishes, setWishes] = useState([]);
    const [comment, setComment] = useState('');
    const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(1);
    const fetchWithToken = useFetchWithToken();
    const { t } = useTexts();
    const { isLoadingFunction, section } = useAuth();

    const handleChangeType = (event) => {
        setSelectedType(event.target.value);
        setPage(1);

    };
    const handleChangeDate2 = (event) => {
        setSelectedEndDate(event);
        setPage(1);
    };
    const handleChangeDate1 = (event) => {
        setSelectedStartDate(event);
        setPage(1);
    };
    const handleChangeBranche = (event) => {
        setSelectedBranche(event);
        setPage(1);

    };

    const loadWishes = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/wishes/derogations`;
            const body = {
                branche: selectedBranche,
                startDate: selectedstartDate,
                endDate: selectedendDate,
                level: selectedType.key,
                user: (selectedPersonne ? selectedPersonne.id : null),
                page
            }
            const data = await fetchWithToken(apiUrl, 'POST', body);
            setWishes(data.wish);
            setTotalPages(data.totalPages);

        } catch (error) {
            console.error('Erreur lors du chargement des vœux:', error);
        }
    };


    useEffect(() => {
        loadWishes();
    }, [selectedType, selectedBranche, page, selectedPersonne, selectedstartDate, selectedendDate]);

    const handleSelectWish = (wishId, isSelected, index, shiftKey) => {
        if (shiftKey && lastSelectedIndex !== null) {
            const start = Math.min(lastSelectedIndex, index);
            const end = Math.max(lastSelectedIndex, index);
            const newSelectedWishes = [...selectedWishes];
            for (let i = start; i <= end; i++) {
                const wish = wishes[i];
                if (isSelected) {
                    if (!newSelectedWishes.includes(wish.voeu_id)) {
                        newSelectedWishes.push(wish.voeu_id);
                    }
                } else {
                    const wishIndex = newSelectedWishes.indexOf(wish.voeu_id);
                    if (wishIndex > -1) {
                        newSelectedWishes.splice(wishIndex, 1);
                    }
                }
            }
            setSelectedWishes(newSelectedWishes);
        } else {
            setSelectedWishes((prevSelected) => {
                if (isSelected) {
                    return [...prevSelected, wishId];
                } else {
                    return prevSelected.filter((id) => id !== wishId);
                }
            });
        }
        setLastSelectedIndex(index);
    };

    const HandlePersonneSearch = (newuser, function_id) => {
        // Handle add trainee logic
        setSselectedPersonne(newuser)
        setDialogOpenSearch(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleResetPersonne = () => {
        setSselectedPersonne(null);
        setDialogOpenSearch(false)
    };

    const handleOpenSearchDialog = () => {
        setDialogOpenSearch(true);
    };

    const formatSearchUser = (user) => {
        return user.firstname + " " + user.lastname
    };

    const handleBulkValidate = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/wishes/derogations/validate`;
            await fetchWithToken(apiUrl, "POST", { wishIds: selectedWishes, comment });
            setModalOpenValidate(false);
            loadWishes();
            setSelectedWishes([]);
            setComment(''); // Reset the comment after action
        } catch (error) {
            console.error('Erreur lors de la validation des dérogations:', error);
        }
    };

    const handleCheckboxChange = (e, wishId, index) => {
        handleSelectWish(wishId, e.target.checked, index, e.nativeEvent.shiftKey);
    };

    const handleBulkRefuse = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/wishes/derogations/refuse`;
            await fetchWithToken(apiUrl, "POST", { wishIds: selectedWishes, comment });
            setModalOpenValidateRemove(false)

            loadWishes();
            setSelectedWishes([]);
            setComment(''); // Reset the comment after action
            setModalOpenValidate(false)
        } catch (error) {
            console.error('Erreur lors du refus des dérogations:', error);
        }
    };

    if (!isLoadingFunction) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container maxWidth="xl">
            <AppBar position="static" color="default" sx={{ top: 10, boxShadow: "none", background: "none" }}>

                    <Toolbar>
                        <Container maxWidth="xl"
                            sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 2 }}>
                            <FormControl variant="outlined" sx={{ m: 1, minWidth: 240, flex: '1 1 auto' }}>
                                <InputLabel>{t("TEXT_COL_TYPE")}</InputLabel>
                                <Select
                                    value={selectedType}
                                    onChange={handleChangeType}
                                    label={t("TEXT_COL_TYPE")}
                                >
                                    <MenuItem value="">
                                        <em>{t("INPUT_SELECT_ALL")}</em>
                                    </MenuItem>
                                    {LevelOptions.map((option) => (
                                        <MenuItem key={option.key} value={option}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined" sx={{ m: 1, minWidth: 120, flex: '1 1 auto' }}>
                                <InputLabel>{t("LIST_FORMATION_BRANCHE")}</InputLabel>
                                <Select
                                    value={selectedBranche}
                                    onChange={handleChangeBranche}
                                    label="Branche"
                                >
                                    <MenuItem value="">
                                        <em>{t("INPUT_SELECT_ALL")}</em>
                                    </MenuItem>
                                    {branchOptions.map((option) => (
                                        <MenuItem key={option.key} value={option.key}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl variant="outlined" sx={{ m: 1, minWidth: 120, flex: '1 1 auto' }}>
                                <DatePicker
                                    label="Date de début"
                                    value={selectedstartDate}
                                    onChange={handleChangeDate1}
                                    renderInput={(params) => <TextField {...params} />}

                                />
                            </FormControl>

                            <FormControl variant="outlined" sx={{ m: 1, minWidth: 120, flex: '1 1 auto' }}>
                                <DatePicker
                                    label="Date de fin"
                                    value={selectedendDate}
                                    onChange={handleChangeDate2}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </FormControl>

                            <Grid item xs={12}>
                                <Box display="flex" flexDirection="column" alignItems="flex-start" gap={2}>
                                    <TextField
                                        label="Nom de l'utilisateur"
                                        value={(selectedPersonne ? formatSearchUser(selectedPersonne) : "")}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        onClick={handleOpenSearchDialog}
                                        variant="outlined"
                                        sx={{ flex: 1 }}
                                    />
                                </Box>
                            </Grid>
                        </Container>
                    </Toolbar>
            </AppBar>
            <Dialog open={dialogOpenSearch} onClose={() => setDialogOpenSearch(false)} aria-labelledby="search-dialog-title">
                <DialogTitle id="search-dialog-title">{t("FIELD_MESTRE_CAMP")}</DialogTitle>
                <DialogContent>
                    <SearchModal onClick={HandlePersonneSearch} functionMode={0} />
                    <Button variant="contained" color="secondary" onClick={handleResetPersonne}>
                        Réinitialiser
                    </Button>
                </DialogContent>
            </Dialog>
            <Toolbar />
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', gap: '8px' }}>
                    <p style={{
                        margin: 0,
                        padding: 0,
                        fontWeight: 'bold',
                    }}>
                        {t('DEROGATION_DETAIL_NUMBER', selectedWishes.length)}
                    </p>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-around',
                        gap: '8px',
                    }}>
                        <Button variant="contained" color="primary" onClick={e => setModalOpenValidate(true)}>
                            Valider
                        </Button>
                        <Button variant="contained" color="secondary" onClick={e => setModalOpenValidateRemove(true)}>
                            Refuser
                        </Button>
                    </div>
                </Grid>
                <div className='pagination-container'>
                    <Pagination count={totalPages} page={page} onChange={(e, value) => setPage(value)} />
                </div>
                <Grid item xs={12}>
                    {wishes.map((wish, index) => (
                        <Card sx={{ width: '100%', marginBottom: 2 }} key={wish.voeu_id}> {/* Ajusté pour occuper la pleine largeur */}
                            <CardContent
                                sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    alignItems: 'center',
                                    gap: 2
                                }}>
                                <Checkbox
                                    checked={selectedWishes.includes(wish.voeu_id)}
                                    onChange={(e) => handleCheckboxChange(e, wish.voeu_id, index)}
                                />
                                <DerogationItem wish={wish} />
                            </CardContent>
                        </Card>
                    ))}
                </Grid>
                <div className='pagination-container'>
                    <Pagination count={totalPages} page={page} onChange={(e, value) => setPage(value)} />
                </div>
            </Grid>
            <ConfirmeModal
                open={modalOpenValidate}
                message={
                    <>
                        <span style={{ color: style.red, fontSize: '25px' }}>{t("CONFIRM_DEROGATION")}</span>
                        <TextField
                            label="Commentaire"
                            variant="outlined"
                            fullWidth
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            sx={{ mt: 2 }}
                        />
                    </>
                }
                onClose={e => setModalOpenValidate(false)}
                onConfirm={handleBulkValidate}
            />
            <ConfirmeModal
                open={modalOpenValidateRemove}
                message={
                    <>
                        <span style={{ color: style.red, fontSize: '25px' }}>{t("CONFIRM_UNVALIDATE_DEROGATION")}</span>
                        <TextField
                            label="Commentaire"
                            variant="outlined"
                            fullWidth
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            sx={{ mt: 2 }}
                        />
                    </>
                }
                onClose={e => setModalOpenValidateRemove(false)}
                onConfirm={handleBulkRefuse}
            />
        </Container>
    );
};

export default withAdminOrFormationRole(DerogationListTable);
