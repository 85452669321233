import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

import {Box, Collapse, IconButton, Modal} from '@mui/material';
import {Close} from '@mui/icons-material';
import ExpandLess from '@mui/icons-material/ExpandLessRounded';
import ExpandMore from '@mui/icons-material/ExpandMoreRounded';
import PendingActionsIcon from '@mui/icons-material/PendingActionsRounded';
import FormationIcon from '@mui/icons-material/SchoolRounded';
import AvisToWriteIcon from '@mui/icons-material/RateReviewRounded';

import {useAuth} from '../../Context/AuthContext';
import {useFetchWithToken} from '../../hooks/useFetchWithToken';
import formatFirstName from '../../Utils/formatFirstName';
import AvisModal from './AvisModal';
import DashCard from './DashCard';
import './Dashboard.css';
import FormationToValidate from './FormationToValidate';


export default function Dashboard() {
    const fetchWithToken = useFetchWithToken();
    const location = useLocation();
    const user = JSON.parse(localStorage.getItem('user'));
    const [folded, setFolded] = useState(false);
    const [dashCard, setDashCard] = useState([]);
    const {currentFunction} = useAuth();
    const [wishes, setWishes] = useState([]);
    const [formations, setFormations] = useState([]);
    const [avis, setAvis] = useState([]);
    const [openAvisRecap, setOpenAvisRecap] = useState(false);
    const [openFormationRecap, setOpenFormationRecap] = useState(false);
    const [formationToValidate, setFormationToValidate] = useState([]);


    const handleCollapse = () => {
        setFolded(!folded);
    };

    const loadWishes = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/wishes`;
            const data = await fetchWithToken(apiUrl);
            setWishes(data);
        } catch (error) {
            console.error('Erreur lors du chargement des vœux:', error);
        }
    };
    const loadFormations = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/myfomrations`;
            const data = await fetchWithToken(apiUrl);
            setFormations(data);
        } catch (error) {
            console.error('Erreur lors du chargement des vœux:', error);
        }
    };

    async function loadAvis() {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/getaviswait`;
            const data = await fetchWithToken(apiUrl);
            setAvis(data);
            // console.log('avis',data);
        } catch (error) {
            console.error('Failed to load avis (loadAvis):', error);
        }
    }


    async function loadFormationToValidate() {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/getformationtovalidate`;
            const data = await fetchWithToken(apiUrl);
            const tab = [];
            data.formation.map(function (register) {

                const exists = tab.some(item => item.formation_user_id === register.formation_user_id);
                if (!exists) {
                    tab.push({
                        chef: register.firstname + " " + register.lastname,
                        formation: register.level,
                        price: register.price,
                        formation_user_status:register.formation_user_status,
                        formation_user_id: register.formation_user_id,
                        formationId: register.formation_id,
                        date: register.date,
                        endDate: register.end_date,
                    });
                }
            })
            setFormationToValidate(tab);


            //  setChefs(data.chefs);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        loadWishes();
        loadFormationToValidate();
        loadFormations();
        loadAvis();
    }, [currentFunction, location.pathname]);


    useEffect(() => {
        const updatedDashCards = [];
        if (wishes.length > 0) {
            const wishNumber = wishes.length;
            updatedDashCards.push({
                title: 'Voeux en attente',
                number: wishNumber,
                icon: PendingActionsIcon,
                link: '/voeux'
            });
        }
        if (formations.length > 0) {
            const formationNumber = formations.length;
            updatedDashCards.push({
                title: 'Formations en cours',
                number: formationNumber,
                icon: FormationIcon,
                link: '/formations'
            });
        }
        if (avis.length > 0) {
            const avisNumber = avis.length;
            updatedDashCards.push({
                title: 'Avis en attente',
                number: avisNumber,
                icon: AvisToWriteIcon,
                link: 'avis'
            });
        }
        if (formationToValidate.length > 0) {
            updatedDashCards.push({
                title: 'Formation à valider',
                number: formationToValidate.length,
                icon: FormationIcon,
                link: 'formation'
            });
        }
        setDashCard(updatedDashCards);
    }, [wishes, formations, avis, location.pathname, formationToValidate]);

    return (
        <div className='dashboard-container'>
            <div id='welcome'>
                <p>Bonjour {user?.prenom ? formatFirstName(user.prenom) : null} !</p>
                <IconButton
                    onClick={handleCollapse}
                    size='medium'
                    color='inherit'
                >
                    {folded ? <ExpandMore color='inherit'/> : <ExpandLess color='inherit'/>}
                </IconButton>
            </div>
            <Collapse
                orientation='vertical'
                in={!folded}
            >
                <div className='dashboard-card-container'>
                    {dashCard?.map((card, index) => (
                        <DashCard
                            key={index} index={index}
                            card={card} link={card.link}
                            setOpenAvisRecap={setOpenAvisRecap}
                            setOpenFormationRecap={setOpenFormationRecap}
                        />
                    ))}
                </div>
            </Collapse>
            <Modal
                open={openAvisRecap}
                onClose={() => setOpenAvisRecap(false)}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box className='avis-recap dashboard-modal'>
                    <div id='modal-header'>
                        <h3 id='modal-title'>Avis en attente</h3>
                        <IconButton color='inherit' id='close-modal' onClick={() => setOpenAvisRecap(false)}>
                            <Close/>
                        </IconButton>
                    </div>
                    <div className='avis-recap-content'>
                        {avis?.map((av, index) => (
                            <AvisModal key={index} avis={av} index={index} callBack={loadAvis}/>
                        ))}
                    </div>
                </Box>
            </Modal>
            <Modal
                open={openFormationRecap}
                onClose={() => setOpenFormationRecap(false)}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box className='ftv-recap dashboard-modal'>
                    <div id='modal-header'>
                        <h3 id='modal-title'>Validation de formation</h3>
                        <IconButton color='inherit' id='close-modal' onClick={() => setOpenFormationRecap(false)}>
                            <Close/>
                        </IconButton>
                    </div>
                    <div className='avis-recap-content'>
                        {formationToValidate?.map((formation, index) => (
                            <FormationToValidate key={index} formation={formation} index={index} callBack={loadFormationToValidate}/>
                        ))}
                    </div>
                </Box>
            </Modal>
        </div>
    )
}
