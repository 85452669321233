import { useState, useEffect } from "react";
import { Container } from "@mui/material";
import { useFetchWithToken } from "../../hooks/useFetchWithToken";
import { useParams } from "react-router-dom";

export default function Organigram() {
    const [organigramData, setOrganigramData] = useState([]);
    const fetchWithToken = useFetchWithToken();
    const { id } = useParams();
    const [units, setUnits] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [unitsFilter, setunitsFilter] = useState(null);

    async function loadUnits() {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/chilsstruct/`;
        const data = await fetchWithToken(apiUrl);
        setUnits(data);

        if (id) {
            const specificUnit = data.find(u => u.id.toString() === id);
            if (specificUnit) {
                const filterData = data.filter(u => u.parent_id === specificUnit.id || u.id === specificUnit.id);
                filterData.sort((a, b) => (b.diff === 0) - (a.diff === 0));
                setunitsFilter(filterData);
            }
        } else {
            const specificUnit = data.find(u => u.diff === 0);
            setSelectedUnit(specificUnit);
            const filterData = data.filter(u => u.diff === 1 || u.diff === 0);
            filterData.sort((a, b) => (b.diff === 0) - (a.diff === 0));
            setunitsFilter(filterData);
        }
    }

    const findParent = async (unit) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/getBreacrump/${unit.parent_id}`;
        const data = await fetchWithToken(apiUrl);
        return data;
    }

    useEffect(() => {
        const buildOrganigramData = async () => {
            const organigram = [];

            for (const unit of units) {
                if (unit.parent_id) {
                    const parentData = await findParent(unit);
                    const hierarchy = parentData.map(parent => ({
                        id: parent.id,
                        name: parent.nom,
                        parent_id: parent.parent_id,
                        children: []
                    }));

                    hierarchy.push({
                        id: unit.id,
                        name: unit.nom,
                        parent_id: unit.parent_id,
                        children: []
                    });

                    let currentLevel = organigram;
                    hierarchy.forEach((node, index) => {
                        let existingNode = currentLevel.find(n => n.id === node.id);
                        if (!existingNode) {
                            existingNode = { ...node };
                            currentLevel.push(existingNode);
                        }
                        if (index < hierarchy.length - 1) {
                            if (!existingNode.children) {
                                existingNode.children = [];
                            }
                            currentLevel = existingNode.children;
                        }
                    });
                }
            }

            setOrganigramData(organigram);
        };

        if (units.length > 0) {
            buildOrganigramData();
        }
    }, [units]);

    useEffect(() => {
        loadUnits();
    }, []);

    useEffect(() => {
        // console.log('organigramData', organigramData);
    }, [organigramData]);

    return (
        <Container>
            {/* Render the organigramData here */}
        </Container>
    );
}