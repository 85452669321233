import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { branchOptions } from '../../Utils/BranchOption';
import { LevelOptions } from '../../Utils/LevelOption';
import { calculateEndDate, formatDate, transformFunctionOption } from '../../Utils/functionTransforms';
import { formatErrors, formatErrorsArray } from "../../Utils/formatErrors";
import useTexts from "../../hooks/useTexte";
import './styles/WishItem.css';
import { useFetchWithToken } from "../../hooks/useFetchWithToken";
import { Check, CheckCircleOutlineRounded, CloseRounded } from "@mui/icons-material";
import { ValidateButton } from "../../Utils/ValidateButton";

const WishItem = ({ wish, user, type }) => {
    const { t } = useTexts();
    const level = LevelOptions.find((branch) => wish.level === branch.key);
    const branch = branchOptions.find((branch) => wish.branch === branch.key);
    const invalideDate = type === "voeux" ? JSON.parse(wish.invalide_data) : false;
    const [formationData, setFormationData] = useState([]);
    const [formation, setFormation] = useState(0);
    const [isRequireValidate, setIsRequireValidate] = useState(false);

    const fetchWithToken = useFetchWithToken();


    const renderWishStatusWait = () => {
        let userHasValide = false;
        let chefHasValide = false;
        formationData.map((formation) => {
            if (formation.statusValidation) {
                if (formation.statusValidation.find(f => f.role === 1)) {
                    userHasValide = true;
                }
                if (formation.statusValidation.find(f => f.role === 2)) {
                    chefHasValide = true;
                }
            }
        });


        return (
            <div>
                <Typography
                    sx={{ fontFamily: "ubuntu", fontWeight: "bold" }}
                    variant="body1"
                    className=""
                >
                    {formation.nom} <span
                        style={{
                            color: 'orange',
                            margin: "5px"
                        }}
                    >{t("PLACE_HAS_PROPOSE")}</span>

                </Typography>

                <Typography
                    sx={{
                        display: { xs: 'block', sm: 'flex' },
                        fontFamily: "ubuntu",
                        alignItems: 'center',
                        gap: 1
                    }}
                >

                </Typography>

                <Typography
                    sx={{ fontFamily: "ubuntu", marginTop: 1 }}
                    variant="body2"
                    className=""
                >
                    Lieu de formation : {formation.lieu}
                </Typography>
                {userHasValide ? (
                    <Typography
                        sx={{
                            display: 'flex',
                            color: "green",
                            alignItems: 'center',
                            fontFamily: 'ubuntu',
                            marginTop: 1,
                        }}
                        variant="body2"
                    >
                        <CheckCircleOutlineRounded color="success" sx={{ marginRight: '8px' }} />
                        {t('CHEF_HAS_VALIDATE')}
                    </Typography>
                ) : (
                    <Typography
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: "orange",
                            fontFamily: 'ubuntu',
                            marginTop: 1,
                        }}
                        variant="body2"
                    >
                        <CloseRounded color="warning" sx={{ marginRight: '8px' }} />
                        {t('CHEF_HAS_TO_VALIDATE')}
                    </Typography>
                )}


                {chefHasValide ? (

                    <Typography
                        sx={{
                            display: 'flex',
                            color: "green",
                            alignItems: 'center',
                            fontFamily: 'ubuntu',
                            marginTop: 1,
                        }}
                        variant="body2"
                    >
                        <CheckCircleOutlineRounded color="success" sx={{ marginRight: '8px' }} />
                        {t('STAGIAIRE_HAS_VALIDATE')}
                    </Typography>
                ) :

                    <Typography
                        sx={{
                            display: { xs: 'block', sm: 'flex' },
                            fontFamily: "ubuntu",
                            alignItems: 'center',
                            gap: 1
                        }}
                    >
                        <span
                            style={{
                                color: 'orange',
                                marginRight: '4px',
                                alignItems: 'center',
                                borderRadius: '5px',
                                display: 'flex',
                                gap: '2px'
                            }}
                        >
                            <CloseRounded color="warning" sx={{ marginRight: '8px' }} />
                            {t('STAGIAIRE_HAS_TO_VALIDATE')}
                        </span>

                    </Typography>

                }
            </div>

        )
    }
    const renderWishStatus = () => {

        if (wish.valide) {
            if (!formationData.length) {
                return (
                    <Typography
                        sx={{ fontFamily: "ubuntu", fontWeight: "bold" }}
                        variant="body1"
                        className=""
                    >
                        {t("TEXT_NO_FORMATION")}
                    </Typography>
                );
            }
            if (!isRequireValidate) {
                return formationData.map((formation, idx) => {
                    let text = "";
                    let subtext = "";
                    let color = "";
                    if (formation.status === "open") {
                        text = t("WAIT_ATTRIBUTION");
                        color = "black"
                    } else if (formation.status === "selecting") {
                        text = t("WAIT_LIST_FORMATION_RUN");
                        color = "black"
                    } else if (formation.status === "selected") {

                        text = t("WAIT_LIST_FORMATION");
                        subtext = t("WAIT_LIST_FORMATION_SUB_TEXT");
                        color = "orange"
                    }
                    if (formation.close) {
                        text = t("WAIT_LIST_FORMATION_CLOSE");
                        subtext = t("WAIT_LIST_FORMATION_SUB_TEXT_CLOSE");
                        color = "red"
                    }
                    return (
                        <div key={idx}>
                            <Typography
                                sx={{ fontFamily: "ubuntu", fontWeight: "bold" }}
                                variant="body1"
                                className=""
                            >
                                {formation.nom}

                                <span
                                    style={{
                                        color: color,
                                        margin: "5px"
                                    }}
                                > {text}
                                </span>

                            </Typography>

                            {subtext && (
                                <Typography
                                    sx={{ fontFamily: "ubuntu", fontWeight: "bold" }}
                                    variant="body1"
                                    className=""
                                >
                                    {subtext}
                                </Typography>
                            )}



                        </div>
                    );
                });
            } else {
                return renderWishStatusWait()
            }
        } else {
            return (
                <>
                    <Typography
                        sx={{ fontFamily: "ubuntu", fontWeight: 'bold' }}
                        variant="body1"
                        className="requirevalidation"
                        dangerouslySetInnerHTML={{ __html: t("TEXT_INSCRIPTON_NEED_DEROGATION_LIST") }}
                    />
                    {wish.invalide_data && wish.invalide_data.length > 0 && (
                        <ul>
                            {formatErrorsArray(wish.invalide_data, t, true).map((error, index) => (
                                <li key={index}>{error}</li>
                            ))}
                        </ul>
                    )}
                </>
            );
        }
    };

    const renderFormationStatus = () => {


        return (
            <div>
                <Typography
                    sx={{ fontFamily: "ubuntu", fontWeight: "bold" }}
                    variant="body1"
                    className=""
                >
                    {wish.nom} <span
                        style={{
                            color: 'green',
                            margin: "5px"

                        }}
                    >{t("IS_IN_FORMATION")}</span>
                </Typography>


                <Typography
                    sx={{
                        display: { xs: 'block', sm: 'flex' },
                        fontFamily: "ubuntu",
                        alignItems: 'center',
                        gap: 1
                    }}
                >

                </Typography>
                <Typography
                    sx={{ fontFamily: "ubuntu", marginTop: 1 }}
                    variant="body2"
                    className=""
                >
                    Lieu de formation : {wish.lieu}
                </Typography>
            </div>
        );

    };
    const loadWishesData = async () => {
        try {
            console.log(wish)
            const body = {
                "parentSearch": true,
                "user_id_search": user.user_id,
            }
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/wishes/${wish.id}/data`;
            const data = await fetchWithToken(apiUrl, "POST", body);
            setFormationData(data.formations);
            data.formations.map((formation) => {
                if (formation.statusInscription) {
                    if (formation.statusInscription.status === 'WAIT_VALIDATION') {
                        setIsRequireValidate(true);
                        setFormation(formation);
                    }
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (type == "voeu") {
            loadWishesData();
        }
    }, []);
    return (
        <div className="wish-item-container" >
            <div className='formation-detail'>
                <img src={branch.logo} alt="Branch logo" loading='lazy' />
                <Typography fontSize={16} variant="body1">{level.label}</Typography>
            </div>
            <div id='date-from-to'>
                <p>du <span id='chip'>{formatDate(wish.date)}</span></p>
                <p>au <span id='chip'>{calculateEndDate(wish.date, branch.key, level.key)}</span></p>
            </div>
            <div className='wish-item-validation'>
                {type == "voeu" ? (
                    renderWishStatus()
                ) : (
                    renderFormationStatus()
                )}
            </div>
        </div>
    );
};

export default WishItem;
