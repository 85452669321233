import React, { useState, useEffect } from 'react';
import { branchOptions } from "../Utils/BranchOption";
import {
    Button,
    Typography,
    Box,
    Card, CardContent,
    CircularProgress,
    Grid,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@mui/material';
import { useFetchWithToken } from "../hooks/useFetchWithToken";
import useTexts from "../hooks/useTexte";
import withAdminRole from "../hoc/withAdminRole";

function QuestinaireSectionPage() {
    const { t } = useTexts();
    const [questionnaires, setQuestionnaires] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedQuestionnaires, setSelectedQuestionnaires] = useState({});

    const fetchWithToken = useFetchWithToken();

    useEffect(() => {
        const fetchQuestionnaires = async () => {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formeval`;
            const data = await fetchWithToken(apiUrl);

            // Préparer les sélections initiales à partir des données
            const initialSelections = {};
            data.forEach(questionnaire => {
                questionnaire.sections.forEach(sectionId => {
                    initialSelections[sectionId] = questionnaire.id;
                });
            });

            setQuestionnaires(data);
            setSelectedQuestionnaires(initialSelections);
            setLoading(false);
        };

        fetchQuestionnaires();
    }, []);

    const handleQuestionnaireChange = (sectionId, questionnaireId) => {
        setSelectedQuestionnaires(prevSelected => ({
            ...prevSelected,
            [sectionId]: questionnaireId
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Préparer les données pour l'envoi
        const sectionsWithQuestionnaires = Object.keys(selectedQuestionnaires).map(sectionId => ({
            section_id: parseInt(sectionId),
            questionnaire_id: selectedQuestionnaires[sectionId] || null // ou null si aucun questionnaire sélectionné
        }));

        // Envoyer les données
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/save-form-eval`;
            const response = await fetchWithToken(apiUrl, "POST",
                {data:sectionsWithQuestionnaires},
            );

            if (!response.ok) {
                throw new Error('Erreur lors de la sauvegarde des données');
            }

        } catch (error) {
            console.error("Erreur lors de la sauvegarde :", error);
        }
    };


    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ mt: 4, mx: 2 }}>
            <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
                <Grid container spacing={3}>
                    {branchOptions.map((branch) => (
                        <Grid item xs={12} md={6} lg={4} key={branch.id}>
                            <Card variant="outlined" sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" color="primary">{branch.label}</Typography>
                                    <FormControl fullWidth sx={{ mt: 2 }}>
                                        <Select
                                            value={selectedQuestionnaires[branch.id] || ""}
                                            onChange={(e) => handleQuestionnaireChange(branch.id, e.target.value)}
                                            displayEmpty
                                        >
                                            <MenuItem value="">Aucun</MenuItem>
                                            {questionnaires.map(questionnaire => (
                                                <MenuItem key={questionnaire.id} value={questionnaire.id}>
                                                    {questionnaire.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                    Sauvegarder
                </Button>
            </Box>
        </Box>
    );
}

export default withAdminRole(QuestinaireSectionPage);
