import logoloup from './../assets/svg/pictos_branches/LOUVETEAUX.svg'; // loups
import logofeu from './../assets/svg/pictos_branches/EQUIPIERES_PILOTES.svg'; // feu
import logoecl from '../assets/svg/pictos_branches/ECLAIREURS.svg'; // lys
import logoroute from './../assets/svg/pictos_branches/EQUIPIERS_PILOTES.svg'; // route
import logoguide from '../assets/svg/pictos_branches/ECLAIREUSES.svg'; // trèfle
import logoterr from './../assets/svg/pictos_branches/TERRITORIAL.svg';


export const branchOptions = [
    {
        key: 'louvetisme', label: 'Louvetisme', section: 'mixte', id: 1, logo: logoloup, 'choicecep': 0,
    },
    {key: 'eclaireur', label: 'Éclaireurs', section: 'scout', id: 2, logo: logoecl, choicecep: 1,},
    {key: 'route', label: 'Route', section: 'scout', id: 5, logo: logoroute, choicecep: 0,},
    {key: 'eclaireuse', label: 'Éclaireuses', section: 'guide', id: 3, logo: logoguide, choicecep: 1,},
    {key: "feu", label: "Feu", section: 'guide', id: 4, logo: logofeu, 'choicecep': 0},
    {key: "territorial", label: "Territoriale", section: 'mixte', id: 6, logo: logoterr, choicecep: 0},
];

