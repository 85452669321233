import React, { useEffect, useState } from 'react';
import { Box, Grid, CircularProgress, Typography, Breadcrumbs as MUIBreadcrumbs } from '@mui/material';
import { useParams, useNavigate, Link } from "react-router-dom";
import { useFetchWithToken } from '../hooks/useFetchWithToken';
import { useAuth } from '../Context/AuthContext';
import StructureItem from "../composant/Structure/StructureItem";
import { Link as RouterLink } from 'react-router-dom';
import Organigram from '../composant/Organigram/Organigram';

function Maitrise() {
    const { id } = useParams();
    const navigate = useNavigate();
    const fetchWithToken = useFetchWithToken();
    const { currentFunction } = useAuth();
    const [units, setUnits] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [unitsFilter, setunitsFilter] = useState(null);
    const [loading, setLoading] = useState(true);
    const [breadCrump, setBreadCrump] = useState([]);
    const customSort = (a, b) => {
        const order = ['Province', 'District', 'Groupe'];

        // Obtenir l'index des types dans l'ordre défini
        const indexA = order.indexOf(a.type);
        const indexB = order.indexOf(b.type);

        // Si les deux types sont dans l'ordre
        if (indexA !== -1 && indexB !== -1) {
            return indexA - indexB;
        }

        // Si a.type est dans l'ordre mais pas b.type
        if (indexA !== -1) {
            return -1;
        }

        // Si b.type est dans l'ordre mais pas a.type
        if (indexB !== -1) {
            return 1;
        }

        // Si aucun des types n'est dans l'ordre, les trier par ordre alphabétique
        if (a.type < b.type) {
            return -1;
        }
        if (a.type > b.type) {
            return 1;
        }
        return 0;
    };
    async function loadUnits() {
        setLoading(true);
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/chilsstruct/`;
        const data = await fetchWithToken(apiUrl);
        setUnits(data);

        if (id) {
            const specificUnit = data.find(u => u.id.toString() === id);
            if (specificUnit) {
                const filterData = data.filter(u => u.parent_id === specificUnit.id || u.id === specificUnit.id);
                filterData.sort((a, b) => (b.diff === 0) - (a.diff === 0));
                setunitsFilter(filterData);
            }
        } else {
            const specificUnit = data.find(u => u.diff === 0);
            setSelectedUnit(specificUnit);
            const filterData = data.filter(u => u.diff === 1 || u.diff === 0);
            filterData.sort((a, b) => (b.diff === 0) - (a.diff === 0));
            setunitsFilter(filterData);
        }
        setLoading(false);
        console.log(units);
    }

    function loadBreadCrump() {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/getBreacrump/${id}`;
        fetchWithToken(apiUrl).then(data => setBreadCrump(data));
    }

    useEffect(() => {
        // console.log('breadCrump', breadCrump);
    }, [breadCrump]);

    useEffect(() => {
        loadBreadCrump();
        loadUnits();
    }, [currentFunction, id]);

    const handleSelectUnit = (unit) => {
        navigate(`/mes-maitrises/${unit.id}`);
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    // Fonction de comparaison personnalisée
    const filteredUnitsName = unitsFilter.filter(unit => !unit.nom.toUpperCase().startsWith('DEPARTEMENT'));

    // Trier la variable unitsFilter
    const sortedUnitsFilter = filteredUnitsName.sort(customSort);
    return (
        <Box noValidate autoComplete="off" sx={{ '& > :not(style)': { m: 1 } }}>
            <Organigram units={units} />
            <Grid item xs={12}>
                <MUIBreadcrumbs aria-label="breadcrumb">
                    {breadCrump.map((node, index) => {
                        const isLast = index === breadCrump.length - 1;
                        return isLast ? (
                            <Typography color="text.primary" key={node.id}>
                                {node.nom}
                            </Typography>
                        ) : (
                            <Box key={node.id} sx={{ padding: '8px 16px', display: 'inline-block' }}>
                                <Link
                                    component={RouterLink}
                                    to={`/mes-maitrises/${node.id}`}
                                    underline="none"
                                    color="inherit"
                                >
                                    {node.nom}
                                </Link>
                            </Box>
                        );
                    })}
                </MUIBreadcrumbs>

                <Grid container spacing={2}>
                    {sortedUnitsFilter.map((unit, index) => (
                        <Grid item xs={12} sm={12} md={12} key={unit.id}>
                            <StructureItem structure={unit} current={selectedUnit} list={units}  size={sortedUnitsFilter.length}  />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Box>
    );
}

export default Maitrise;
