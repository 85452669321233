import React, {useState} from 'react';
import {TextField, Typography, Button, Snackbar, FormControlLabel, Checkbox} from '@mui/material';
import {useFetchWithToken} from "../../hooks/useFetchWithToken";
import useTexts from "../../hooks/useTexte";

const FeedbackForm = ({ chef, editable, currentAvis, loadChef, structure, selectedChefType, isAdmin, diff, onClose }) => {
    const fetchWithToken = useFetchWithToken();
    const {t} = useTexts();
    const defaultText = currentAvis && currentAvis.avis_avis ? currentAvis.avis_avis : '';
    const defaultCheckbox = currentAvis && currentAvis.idontknow ? true : false;
    const [feedback, setFeedback] = useState(defaultText);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleChange = (event) => {
        setFeedback(event.target.value);
    };

    const [checkboxChecked, setCheckboxChecked] = useState(defaultCheckbox);
    const handleCheckboxChange = (event) => {
        setCheckboxChecked(event.target.checked);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const cleanFeedback = feedback.replace(/\n/g, "");
        const cleanDefaultText = defaultText.replace(/\n/g, "");

        if (cleanFeedback !== "" || checkboxChecked) {
            let apiUrl = "";
            let body = {}
            if (isAdmin) {
                console.log(chef)
                apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/saveAvis`;
                body = {
                    feedback,
                    idontknow: false,
                    diff:diff,
                    user_id: chef.chef_id,
                    type: selectedChefType,
                    user_fonction_id: chef.id_function
                };

            } else {
                apiUrl = `${process.env.REACT_APP_API_URL}/api/unite/saveAvis`;
                body = {
                    feedback,
                    idontknow: checkboxChecked,
                    user_id: chef.id,
                    type: selectedChefType,
                    user_fonction_id: chef.fonction_id
                };

            }

            fetchWithToken(apiUrl, 'POST', body).then(data => {
                loadChef();
                onClose();
            });
        } else {
            setOpenSnackbar(true);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Typography variant="h6" gutterBottom>
                {t("MY_AVIS_IN_CHEF")}
            </Typography>
            <Typography variant='body1' gutterBottom>
                {t("AVIS_DEFAULT")}
            </Typography>
            <TextField
                fullWidth
                name=""
                label={t("TEXT_LABEL_AVIS")}
                multiline
                rows={2}
                cols={50}
                variant="outlined"
                value={feedback}
                onChange={handleChange}
                margin="normal"
                disabled={!editable}  // Disable the field if not editable
            />

            <div>

            </div>

            {(editable && structure && structure.diff === 2) && (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checkboxChecked}
                            onChange={handleCheckboxChange}
                            color="primary"
                            disabled={!editable}
                        />
                    }
                    label={t("I_DONT_KNOW_CHEF")}

                />
            )}
            {editable && (
                <Button type="submit" variant="contained" color="primary">
                    {t("BUTTON_SOUMETTRE")}
                </Button>
            )}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={t("ERROR_AVIS_VIDE")}
            />
        </form>
    );
};

export default FeedbackForm;
