import React, {useEffect, useState} from 'react';
import {useAuth} from "../Context/AuthContext";
import client from "../sanityClient";
import {useLocation, useNavigate} from "react-router-dom";
import CMSTop from "../composant/CMSTop";
import {Toolbar} from "@mui/material";
import Header from "../composant/Core/Header";
import useTexts from "../hooks/useTexte";
import ErrorModal from "../composant/Modal/ErrorModal";
import Dashboard from '../composant/Dashboard/Dashboard';
import './DefaultLayout.css'

const DefaultLayout = ({children}) => {
    const {token, logout, currentFunction, functionOptions} = useAuth();
    const navigate = useNavigate(); // Import useNavigate

    const [cmsData, setCmsData] = useState(false)
    const location = useLocation(); // Utiliser useLocation pour obtenir l'objet location
    const [mobileOpen, setMobileOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const {authErrorNoFunction} = useAuth();
    const authErrorCloseHand = () => {
        logout()
        navigate('/login', {state: null}); // Rediriger en purgant location.state
    }
    const {t} = useTexts()
    const handleSearchToggle = () => {
        setSearchOpen(!searchOpen);
    };


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    useEffect(() => {
        if (token) {
            let codepage = location.pathname; // Obtenir le chemin de l'URL

            if (codepage.startsWith("/page/")) {

            }

            if (!codepage.startsWith("/avis-formation/")) {
                if (codepage && !codepage.startsWith("/page/")) {
                    client.fetch(`
          *[_type == "editorialPage" && codepage == $codepage]{
            title,
            content,
               imageHero,
                    description,
            list[]{
              image,
              text,
              link->{
                _id,
                title
              }
            }
          }
        `, {codepage}).then((data) => {
                        // Traiter les données récupérées et les stocker dans un état local si nécessaire
                        setCmsData(data[0]); // Supposons que vous obtenez une liste et que vous prenez le premier élément

                    });
                }
            }
        } else {
            setCmsData(false)
        }
    }, [token, location]);
    if (!authErrorNoFunction) {
        return (

            <div className='defaultLayout-container'>
                {token && <Header handleDrawerToggle={handleDrawerToggle} handleSearchToggle={handleSearchToggle} />}
                <div className='defaultLayout-mainContent'>
                    <Dashboard />
                    <div className="cmsPageTop">
                        {(cmsData && token) && (
                            <CMSTop cmsData={cmsData} />
                        )}
                        <div id="_content" className="row content">
                            <div id="ctl00__divContentMid" className="col-xs-12 box box-default">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
                <ErrorModal open={authErrorNoFunction} error={t("NO_FUNCTION")} onClose={authErrorCloseHand} />

            </div>
        );
    } else {
        return (

            <div className="">
                <ErrorModal open={authErrorNoFunction} error="no_function" onClose={authErrorCloseHand}/>
            </div>
        );
    }

}

export default DefaultLayout;
