import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddIcon from '@mui/icons-material/Add';

import {useFetchWithToken} from '../hooks/useFetchWithToken'; // Assurez-vous que le chemin d'importation est correct
import AddWishForm from '../composant/Wish/AddWishForm';
import {useAuth} from '../Context/AuthContext';
import {branchOptions} from '../Utils/BranchOption';
import {LevelOptions} from '../Utils/LevelOption';
import ErrorModal from "../composant/Modal/ErrorModal";
import ValidateModal from "../composant/Modal/ValidateModal";
import useTexts from "../hooks/useTexte";
import WishListTable from "../composant/Wish/WishListTable";
import ConfirmeModal from "../composant/Modal/ConfirmeModal";
import {style} from "../Utils/style";
import NoResultFound from "../composant/NoResultFound";
import {useConfirm} from "../Provider/ConfirmProvider";
import './styles/WishListPage.css';

const WishListPage = () => {
    const fetchWithToken = useFetchWithToken();
    const {t} = useTexts();
    const {requestConfirm} = useConfirm(); // Utiliser le hook pour obtenir la fonction de demande de confirmation

    const {isLoadingFunction, functionOptions, section, currentFunction} = useAuth();
    const [open, setOpen] = useState(false);
    const [selectedType, setSelectedType] = useState('');
    const [selectedBranche, setSelectedBranche] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [showClose, setShowClose] = useState(false);
    const [showOpen, setShowOpen] = useState(true);

    const [modalOpenError, setModalOpenError] = useState(false);
    const [modalErrorMessage, setModalErrorMessage] = useState("");
    const [modalOpenValidate, setModalOpenValidate] = useState(false);
    const [modalValidateMessage, setModalValidateMessage] = useState("");
    const [modalOpenValidateRemove, setModalOpenValidateRemove] = useState(false);
    const [modalOpenValidateRemoveId, setModalOpenValidateRemoveId] = useState(0);
    const [filterApplied, setFilterApplied] = useState(false);

    const handleChangeType = (event) => {
        setSelectedType(event.target.value);
    };

    const handleChangeStatus = (event) => {
        setSelectedStatus(event.target.value);
    };

    const handleChangeBranche = (event) => {
        setSelectedBranche(event.target.value);
    };

    const handleCloseRemove = (event) => {
        setModalOpenValidateRemove(false);
        setModalOpenValidateRemoveId(0);
    };

    const handleCloseModalError = () => {
        setModalOpenError(false);
        setModalErrorMessage(null);
    };

    const handleCloseModalSucces = () => {
        setModalOpenValidate(false);
        setModalValidateMessage(null);
    };

    const loadWishes = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/wishes`;
            const data = await fetchWithToken(apiUrl);
            setWishes(data);
        } catch (error) {
            console.error('Erreur lors du chargement des vœux:', error);
        }
    };

    useEffect(() => {
        loadWishes();
    }, [currentFunction]);


    const createWishCall = async (wish) => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/wishe`;
            await fetchWithToken(apiUrl, 'POST', (wish));

            handleCloseRemove();
            loadWishes();
            setOpen(false);
        } catch (error) {
            const response = JSON.parse(error.message);
            setModalErrorMessage(response.status);
            setModalOpenError(true);
            console.error('Erreur lors de la suppression du vœu utilisateur:', error);
        }

    };
    const addWishToAPI = async (wish) => {
        if (wish) {
            try {
                const branchNeedValidationForGroup = ["louvetisme", "eclaireur", "eclaireuse"]
                const branchNeedVerifFunctioTern = ["territorial"]
                const FunctionNeedVerifFunctionTer = ["COM", "COMAD", "CH", "CADJ"]
                const chefFunction = functionOptions.find(func => func.id == currentFunction);
                if (chefFunction.type == "Groupe" && branchNeedValidationForGroup.includes(wish.branch)) {
                    requestConfirm(t("GROUP_ASK_UNITE_FOMRATION_MESSAGE"), () => {
                        createWishCall(wish);
                    });
                } else if (!FunctionNeedVerifFunctionTer.includes(chefFunction.fonction) && branchNeedVerifFunctioTern.includes(wish.branch)) {
                    requestConfirm(t("TERRITORIAL_VERIF_FUNCTION_MESSAGE"), () => {
                        createWishCall(wish);
                    });
                } else {
                    createWishCall(wish);
                }

            } catch (error) {
                const response = JSON.parse(error.message);
                setModalErrorMessage(response.status);
                setModalOpenError(true);
                console.error('Erreur lors de la suppression du vœu utilisateur:', error);
            }
        }
    };

    const onDeleteWish = async (voeuId) => {
        setModalOpenValidateRemoveId(voeuId);
        setModalOpenValidateRemove(true);
    };

    const onDeleteWishCallback = async () => {
        try {
            handleCloseRemove();
            const updatedWishes = wishes.filter(wish => wish.voeu_id !== modalOpenValidateRemoveId);
            setWishes(updatedWishes);
            await deleteWish(modalOpenValidateRemoveId);
            loadWishes();
        } catch (error) {
            console.error('Erreur lors de la suppression du vœu:', error);
        }
    };

    const deleteWish = async (voeuUserId) => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/wishes/${voeuUserId}`;
            await fetchWithToken(apiUrl, "DELETE");
        } catch (error) {
            console.error('Erreur lors de la suppression du vœu utilisateur:', error);
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [wishes, setWishes] = useState([]);

    if (!isLoadingFunction) {
        return <Box sx={{display: 'flex', justifyContent: 'center', p: 3}}><CircularProgress/></Box>;
    }

    const addWish = async (wish) => {
        await addWishToAPI(wish);
    };

    const filteredWishes = wishes.filter(wish => {
        const matchesType = selectedType ? wish.level === selectedType.key : true;
        const matchesBranche = selectedBranche ? wish.branch === selectedBranche : true;
        const matchesStatus = selectedStatus ? wish.status === selectedStatus : true;
        let  matchesClose =   true;
        let  matchesOpen =   true;

        if (wish.close == 1 && showClose == false){
            matchesClose = false;
        }
        if (wish.close == 0 && showOpen == false){
            matchesOpen = false;
        }
        return matchesType && matchesBranche && matchesStatus && matchesClose && matchesOpen;
    });

    const filterbranchOptions = branchOptions.filter(branchOption => {
        return branchOption.section == section || branchOption.section == "mixte";
    });

    return (
        <Container maxWidth="xl">
            <AppBar position="static" color="default" sx={{ top: 10, boxShadow: "none", background: "none" }}>
                <div className='wishList-header'>

                    <Button
                        onClick={handleOpen}
                        startIcon={<AddIcon />}
                        sx={{
                            minWidth: 200,
                            width: { xs: '100%', sm: 'auto' }, // Full width on mobile
                            fontSize: '14px !important',
                        }}
                        className='azimuth-button'
                    >
                        {t("TEXT_ADD_WISH")}
                    </Button>
                </div>
                <div className='filters-container'>
                    <FormControl variant="outlined" sx={{ m: 1, minWidth: 240, flex: '1 1 auto' }}>
                        <InputLabel>{t("TEXT_COL_TYPE")}</InputLabel>
                        <Select
                            value={selectedType}
                            onChange={handleChangeType}
                            label={t("TEXT_COL_TYPE")}
                        >
                            <MenuItem value="">
                                <em>{t("INPUT_SELECT_ALL")}</em>
                            </MenuItem>
                            {LevelOptions.map((option) => (
                                <MenuItem key={option.key} value={option}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" sx={{ m: 1, minWidth: 120, flex: '1 1 auto' }}>
                        <InputLabel>{t("LIST_FORMATION_BRANCHE")}</InputLabel>
                        <Select
                            value={selectedBranche}
                            onChange={handleChangeBranche}
                            label="Branche"
                        >
                            <MenuItem value="">
                                <em>{t("INPUT_SELECT_ALL")}</em>
                            </MenuItem>
                            {filterbranchOptions.map((option) => (
                                <MenuItem key={option.key} value={option.key}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" sx={{m: 1, minWidth: 120, flex: '1 1 auto'}}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={showClose}
                                    onChange={(event) => setShowClose(event.target.checked)}
                                />
                            }
                            label={t('SHOW_CLOSE')}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={showOpen}
                                    onChange={(event) => setShowOpen(event.target.checked)}
                                />
                            }
                            label={t('SHOW_OPEN')}
                        />
                    </FormControl>
                </div>
            </AppBar>

            <Grid item xs={12} style={{ marginTop: '16px' }}>
                {filteredWishes.length > 0 ? (
                    filteredWishes.map((wish, index) => (
                        <>
                            <WishListTable
                                wish={wish}
                                key={wish.voeu_id}
                                index={index}
                                close={wish.close}
                                realodWish={loadWishes}
                                onDelete={onDeleteWish}
                            />
                        </>
                    ))
                ) : (
                    <NoResultFound text={t("NO_WISH")}/>

                )}
            </Grid>
            <AddWishForm
                open={open}
                onClose={handleClose}
                onAdd={addWish}
                functionOptions={functionOptions}
                LevelOptions={LevelOptions}
                branchOptions={filterbranchOptions}
            />
            <ErrorModal open={modalOpenError} error={modalErrorMessage} onClose={handleCloseModalError}/>
            <ValidateModal open={modalOpenValidate} message={modalValidateMessage} onClose={handleCloseModalSucces}/>
            <ConfirmeModal open={modalOpenValidateRemove} message={
                <>
                    <span style={{color: style.red, fontSize: '25px'}}>{t("CONFIRM_REMOVE_WISH")}</span>
                    <WishListTable wish={filteredWishes.find(f => f.voeu_id == modalOpenValidateRemoveId)} type={"delete"} />
                </>
            } onClose={handleCloseRemove} onConfirm={onDeleteWishCallback}/>
        </Container>
    )
};

export default WishListPage;
