import React, { useState, useEffect } from 'react';

import PersonIcon from '@mui/icons-material/Person';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import formatFirstName from '../../Utils/formatFirstName';
import useTexts from '../../hooks/useTexte';
import { useFetchWithToken } from '../../hooks/useFetchWithToken';
import FeedbackFormModal from '../Feedback/FeedbackFormModal';
import FeedbackForm from '../Feedback/FeedbackForm';
import './Dashboard.css';

export default function AvisModal({ avis, index , callBack}) {
    const { t } = useTexts();
    const fetchWithToken = useFetchWithToken();
    const [users, setUsers] = useState([]);
    const [openModalAvis, setOpenModalAvis] = useState(false);
    const [currentStructure, setCurrentStructure] = useState({});
    const [currentAvis, setCurrentAvis] = useState(null);
    const [selectedChefType, setSelectedChefType] = useState(null);
    const [selectedChefAvis, setSelectedChefAvis] = useState(null);
    const [modalAvisEdit, setModalAvisEdit] = useState(false);

    const handleCloseModalAvis = () => {
        setOpenModalAvis(false);
        setSelectedChefAvis(null);
    };

    const openModalAvisCallBack = (chef, editable, current, type) => {
        setModalAvisEdit(editable);
        const struct   ={
            diff: type
        }
        setCurrentAvis(current);
        setCurrentStructure(struct)
        setSelectedChefAvis(chef);
        setSelectedChefType(type)
        setOpenModalAvis(true);
    };

    const loadAvis = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/getaviswait`;
            const body = {
            };
            const data = await fetchWithToken(apiUrl, 'POST', body);
            setUsers(data);
            // default expand all
        } catch (error) {
            console.error('Erreur lors du chargement des vœux:', error);
            setUsers([]);
        }
    };
    useEffect(() => {
        loadAvis();
    }, []);
    const type = avis?.is_peda == 1 ? "peda" :  avis.level
    return (
        <div key={index} className='avis-recap-item recap-item'>
            <div id='stagios'>
                <PersonIcon />
                <p>{formatFirstName(avis.firstname)} {avis.lastname}</p>
            </div>
            <div id="group">
                <WorkspacesIcon />
                <p>{avis.struct_name}</p>
            </div>
            <div className='dashboard-card-buttons'>
                <button id='dashboard-card-buttons' onClick={() => openModalAvisCallBack(avis, true, "", type)} className='azimuth-button'>
                    Ajouter un avis
                </button>
            </div>
            <FeedbackFormModal open={openModalAvis} onClose={handleCloseModalAvis}>
                <FeedbackForm
                    chef={selectedChefAvis}
                    structure={currentStructure}
                    onClose={handleCloseModalAvis}
                    editable={modalAvisEdit}
                    loadChef={callBack}
                    currentAvis={currentAvis}
                    selectedChefType={selectedChefType}
                />
            </FeedbackFormModal>
        </div>
    )
}
