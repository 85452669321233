import React, { useState } from 'react';
import EuroRoundedIcon from '@mui/icons-material/EuroRounded';
import PersonIcon from '@mui/icons-material/Person';
import { SchoolRounded } from '@mui/icons-material';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import {useFetchWithToken} from '../../hooks/useFetchWithToken';
import {LevelOptions} from "../../Utils/LevelOption";
import useTexts from "../../hooks/useTexte";

export default function FormationToValidate({ formation, index, callBack }) {
    const [openModal, setOpenModal] = useState(false);
    const [modalAction, setModalAction] = useState(null);
    const fetchWithToken = useFetchWithToken();
    const [openErrorAvis, setOpenErrorAvis] = useState(false);
    const { t } = useTexts();

    const handleCloseErrorAvis = () => {
        setOpenErrorAvis(false);
    };
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString(undefined, options);
    };

    const handleValidateFormation = async () => {
        try {
            const body = {
                formation_user_id: formation.formation_user_status

            };
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formation/validate`;
            await fetchWithToken(apiUrl, "POST", body);
            setOpenModal(false);
            callBack();
        } catch (error) {
           const message = JSON.parse(error.message)
            if (message.require_avis === 1) {
                console.log("Manque votre avis");
                // Ou, si vous avez un état pour afficher les messages d'erreur :
                setOpenErrorAvis(true);
            } else {
                console.log(error);
            }
        }
    };

    const handleRejectFormation = async () => {
        try {
            const body = {
                formation_user_id: formation.formation_user_status
            };
            console.log(formation)
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formation/refuse`;
            await fetchWithToken(apiUrl, "POST", body);
            setOpenModal(false);
            callBack();
        } catch (error) {
            console.log(error);
        }
    };

    const handleOpenModal = (action) => {
        setModalAction(action);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleConfirmAction = () => {
        if (modalAction === 'validate') {
            handleValidateFormation();
        } else if (modalAction === 'reject') {
            handleRejectFormation();
        }
    };
    const levelformation = LevelOptions.find((item) => formation.formation === item.key)

    return (
        <div key={index} className='ftv-recap-item'>
            <div id='group'>
                <PersonIcon />
                <p>{formation.chef}</p>
            </div>
            <div id='group'>
                <CalendarMonthRoundedIcon />
                <div id='formationDate'>
                    <p>{formatDate(formation.date)}</p>
                    <p>{formatDate(formation.endDate)}</p>
                </div>
            </div>
            <div id='group'>
                <span style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    marginBottom: 0,
                    marginRight: 10,
                    gap: '4px'
                }}>
                    <SchoolRounded />
                    {levelformation?.label || 'undefined'}
                </span>
                <span style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    marginBottom: 0,
                    marginRight: 10,
                    gap: '4px'
                }}>
                    <EuroRoundedIcon />
                    {formation.price || 'undefined'}
                </span>
            </div>
            <div className='dashboard-card-buttons'>
                <button id='button-item validate' className='button-item' onClick={() => handleOpenModal('validate')}>Valider</button>
                <button id='button-item refuse' className='button-item' onClick={() => handleOpenModal('reject')}>Refuser</button>
            </div>

            <Dialog
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {modalAction === 'validate' ? 'Confirmer la validation' : 'Confirmer le refus'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {modalAction === 'validate' ? 'Êtes-vous sûr de vouloir valider cette formation?' : 'Êtes-vous sûr de vouloir refuser cette formation?'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleConfirmAction} color="primary" autoFocus>
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openErrorAvis}
                onClose={handleCloseErrorAvis}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Merci de saisir votre avis ?"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleCloseErrorAvis} variant="contained"
                            color="primary">{t("BUTTON_FERMER")}</Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}
