import React, { useEffect, useState } from 'react';
import {
    Button,
    Typography,
    Box,
    Grid,
    CircularProgress,
    Skeleton,
} from '@mui/material';
import { useFetchWithToken } from "../../hooks/useFetchWithToken";
import { useSystem } from "../../Context/System";
import useTexts from "../../hooks/useTexte";
import { useAuth } from "../../Context/AuthContext";
import MyFicheSection from "../MyFicheSection";
import ImageSecure from "../Core/ImageSecure";
import { downloadDocument } from "../../Utils/downloadDocument";
import './styles/MaFiche.css';
import { DownloadRounded, UploadFileRounded } from '@mui/icons-material';

function MaFiche({ id, onClose }) {
    const { token, currentFunction, functionOptions } = useAuth();
    const chefFunction = functionOptions.find(func => func.id == currentFunction);
    const fetchWithToken = useFetchWithToken();
    const { getConfiguration } = useSystem();
    const [isLoadingDetail, setIsLoadingDetail] = useState(true);
    const [isLoadingAttachement, setIsLoadingAttachement] = useState(true);
    const [attachments, setAttachments] = useState([]);
    const sanitaireUrl = getConfiguration('SANITAIRE_URL');
    const { t } = useTexts();
    const [formData, setFormData] = useState();
    const [formState, setFormState] = useState({});

    const handleFileUpload = async (file, type) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', id);

        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form/voeu/${id}/savedoc/${type}`;
            await fetchWithToken(apiUrl, "POST", formData, 1);
            loadAttachement();
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const loadWishesDetail = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form/voeu/${id}`;
            const data = await fetchWithToken(apiUrl);

            const newForm = { "sections": [] };
            for (const key in data.data) {
                const section = data.data[key];
                newForm.sections.push(section);
            }
            setFormData(newForm);
            setFormState(data.answer);
            setIsLoadingDetail(false);
        } catch (error) {
            console.error('Erreur lors du chargement des vœux:', error);
        }
    };

    const loadAttachement = async () => {
        try {
            setIsLoadingAttachement(true);
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form/voeu/${id}/attachement`;
            const data = await fetchWithToken(apiUrl);
            setAttachments(data);
            setIsLoadingAttachement(false);
        } catch (error) {
            console.error('Erreur lors du chargement des vœux:', error);
        }
    };

    useEffect(() => {
        loadWishesDetail();
        loadAttachement();
    }, []);

    const handleDownloadAttachment = (attachmentPath) => {
        downloadDocument(attachmentPath, token, { "type": "stagaire", "formationId": id });
    };

    const handleDeleteAttachment = async (attachmentId) => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/attachment/${attachmentId}`;
            await fetchWithToken(apiUrl, "DELETE");
            loadAttachement();
        } catch (error) {
            console.error('Error deleting attachment:', error);
            loadAttachement();
        }
    };

    const handleCheckboxChange = (sectionIndex, fieldName, optionValue, isChecked, isMultiple) => {
        if (isMultiple) {
            setFormState(prevState => {
                const existingValues = prevState[`${sectionIndex}_${fieldName}`] || [];
                return {
                    ...prevState,
                    [`${sectionIndex}_${fieldName}`]: isChecked ? [...existingValues, optionValue] : existingValues.filter(value => value !== optionValue)
                };
            });
        } else {
            setFormState(prevState => ({
                ...prevState,
                [`${sectionIndex}_${fieldName}`]: isChecked
            }));
        }
    };

    const handleInputChange = (sectionIndex, fieldName, value) => {
        setFormState(prevState => ({
            ...prevState,
            [`${sectionIndex}_${fieldName}`]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form/voeu/${id}/savesanswer`;
            const body = { formData: formState };
            await fetchWithToken(apiUrl, "POST", body);
            onClose();
        } catch (error) {
            console.error('Erreur lors de la soumission du formulaire:', error);
        }
    };

    if (!formData || isLoadingAttachement || isLoadingDetail) {
        return <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}><CircularProgress /></Box>;
    }

    const photoAttachment = attachments.find(attachment => attachment.type === 'photo');
    const docAttachment = attachments.find(attachment => attachment.type === 'doc');

    const sectionFilter = formData.sections.filter(section => {
        if (Array.isArray(section.rules) && section.rules.length > 0) {
            return section.rules.includes(chefFunction.fonction);
        }
        return true;
    });
    const dataImage = { "formation_id": id, "auth": "stagaire" };

    return (
        <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off" id='ficheFormContent'>
            <Grid spacing={3}>
                <div className='fiche-documents'>
                    <Grid item xs={12} sm={6} className='photoInput'>
                        {photoAttachment ? (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
                                <ImageSecure
                                    path={photoAttachment.path}
                                    params={dataImage}
                                    style={{ width: '100px', height: 'auto', marginBottom: '8px' }}
                                />
                                <Button onClick={() => handleDeleteAttachment(photoAttachment.id)} id='upload-photo' variant="outlined">{t("TEXT_DELETE_PHOTO")}</Button>
                            </Box>
                        ) : (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }} id="skeleton">
                                <Skeleton variant="rectangular" width={100} height={100} />
                            </Box>
                        )}
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <input
                                accept="image/*"
                                type="file"
                                onChange={e => handleFileUpload(e.target.files[0], 'photo')}
                                style={{ display: 'none' }}
                                id="upload-photo"
                            />
                            <label htmlFor="upload-photo">
                                <Button variant="contained" component="span">{t("TEXT_ADD_PHOTO")}</Button>
                            </label>
                        </Box>
                    </Grid>

                </div>

                <MyFicheSection formData={formData} formState={formState}
                                handleInputChange={handleInputChange}
                                handleCheckboxChange={handleCheckboxChange}
                                chefFunction={chefFunction}
                />
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }} className="actions-button">
                <Button type="submit" variant="contained" color='success' sx={{ mr: 2 }}>Enregistrer et fermer</Button>
                <Button variant="outlined" color='error' onClick={onClose}>Fermer sans Sauvegarder</Button>
            </Box>
        </Box>
    );
}

export default MaFiche;
