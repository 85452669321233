import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel, MenuItem, Select,
    Typography,
} from '@mui/material';
import {formatDate} from '../../Utils/functionTransforms';
import {useFetchWithToken} from "../../hooks/useFetchWithToken"

import useTexts from "../../hooks/useTexte";
import {LevelOptions} from "../../Utils/LevelOption";
import {branchOptions} from "../../Utils/BranchOption";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularIcon from '@mui/icons-material/Description';
import QuestionnaireIcon from '@mui/icons-material/ListAlt';
import ListIcon from '@mui/icons-material/List';
import {useConfirm} from "../../Provider/ConfirmProvider";
import StagiareCard from "./StagiareCard";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DocumentCard from "../Core/DocumentCard";
import {useAuth} from "../../Context/AuthContext";
import {downloadDocument} from "../../Utils/downloadDocument";
import {CloseRounded, IosShareRounded} from '@mui/icons-material';
import CustomFormBuilder from "../customFormBuilder/CustomFormBuilder";
import './styles/FormationManagerItem.css'

const FormationMangerItem = ({formation, index, functionOptions, addFormation, questionnaires, tasks, callBack}) => {
    const fetchWithToken = useFetchWithToken();
    const {token,} = useAuth()
    const [selectedFormationId, setSelectedFormationId] = useState(null);

    const [users, setUsers] = useState([]);
    const [keyModal, setKeyModal] = useState(0); // Utilisé pour forcer la recréation de FormBuilder
    const [initialFormData, setInitialFormData] = useState(null);
    const [openFormModal, setOpenFormModal] = useState(false);
    const [openListModal, setOpenListModal] = useState(false);
    const [openQuestionnaireModal, setOpenQuestionnaireModal] = useState(false);

    const [openDocumentsModal, setOpenDocumentsModal] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [hasUnsavedChangesFiche, setHasUnsavedChangesFiche] = useState(false);
    const {requestConfirm} = useConfirm(); // Utiliser le hook pour obtenir la fonction de demande de confirmation
    const [nbrStagiaire, setNbrStagiaire] = useState(false);
    const [showUsers, setShowUsers] = useState(false);
    const [attachements, setAttachements] = useState([]);
    const {t} = useTexts();
    const statusIcons = {
        "open": {"icon": <CheckCircleIcon color="success"/>, label: t("OPEN_FORMATION_LABEL")},
        "selecting": {"icon": <CheckCircleIcon color="success"/>, label: t("OPEN_FORMATION_LABEL")},
        "selected": {"icon": <CheckCircleIcon color="success"/>, label: t("OPEN_FORMATION_LABEL")},
        "draft": {"icon": <CheckCircleIcon color="warning"/>, label: t("PROJET_FORMATION_LABEL")},

    };


    const handleExportPDF = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/pdf`;

            const response = await fetchWithToken(apiUrl, "POST", {}, false, "RESPONSE");

            // Vérifier si la réponse est correcte
            if (!response.ok) {
                throw new Error('Erreur lors de la récupération du fichier.');
            }

            // Récupérer le blob depuis la réponse
            const blob = await response.blob();

            // Récupérer l'en-tête Content-Disposition
            const contentDisposition = response.headers.get('Content-Disposition');
            console.log(response.headers)
            // Extraire le nom de fichier depuis l'en-tête (si présent)
            let fileName = ''; // Nom par défaut
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);


                if (fileNameMatch.length > 1) {
                    fileName = fileNameMatch[1];
                }
            }
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Erreur lors de l\'export en PDF :', error);
        }
    };
    const fetchData = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/data`;
            const data = await fetchWithToken(apiUrl, "POST", {});
            setNbrStagiaire(data.nbrStgaire);
        } catch (error) {
            console.error('Erreur lors de la récupération de l\'image :', error);
        }
    };
    useEffect(() => {
        fetchData()
    }, []); // Re-run effect when path or authToken changes

    const handleChangeQuestionnaire = (event) => {
        setSelectedQuestionnaire(event.target.value);
    };


    const handleSelectQuestionnaire = async (formationId, questionnaireId) => {
        // Logique pour associer le questionnaire sélectionné à la formation
        // Exemple d'appel API:
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formationId}/assign-questionnaire`;
        await fetchWithToken(apiUrl, 'POST', { questionnaireId });
        handleCloseQuestionnaireModal();
        callBack();
    };
    const handleCloseDocumentsModal = () => {
        setOpenDocumentsModal(false);
    };
    const handleOpenDocumentsModal = async () => {
        const apiUrlDocuments = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/documents`;
        try {
            const data = await fetchWithToken(apiUrlDocuments);
            setDocuments(data);
            setOpenDocumentsModal(true);
        } catch (error) {
            console.error("Error fetching documents:", error);
        }
    };
    const handleOpenQuestionaire = async () => {
        const apiUrlDocuments = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/openfiche`;
        try {
            const data = await fetchWithToken(apiUrlDocuments);
            callBack();
        } catch (error) {
            console.error("Error fetching documents:", error);
        }
    };

    const [selectedQuestionnaire, setSelectedQuestionnaire] = useState('');

    const handleFileUpload = async () => {
        const formData = new FormData();
        formData.append('file', selectedFile);
        const apiUrlUpload = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/document`;
        try {
            await fetchWithToken(apiUrlUpload, 'POST', formData, 1);
            handleOpenDocumentsModal(); // Refresh the documents list
        } catch (error) {
            console.error("Error uploading document:", error);
        }
    };
    const handleExportExcel = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/answer?format=pdf`;

            // Exemple de données à envoyer au backend (formData)
            const formData = {};
            const blob = await fetchWithToken(apiUrl, "POST", formData, false, "BLOB");

            // Téléchargement du fichier PDF
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'export.xlsx');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Erreur lors de l\'export en PDF :', error);
        }
    };

    const handleDeleteDocument = async (documentId) => {
        const apiUrlDelete = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/document/${documentId}`;
        try {
            await fetchWithToken(apiUrlDelete, 'DELETE', {});
            handleOpenDocumentsModal(); // Refresh the documents list
        } catch (error) {
            console.error("Error deleting document:", error);
        }
    };

    const handleDownloadDocument = (filename) => {
        downloadDocument(filename, token, {"type": "mestre", "formationId": formation.id})
    };

    const listUserSelecing = async () => {
        try {
            const apiUrlAttachement = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/attachements`;
            await fetchWithToken(apiUrlAttachement).then(data => {
                setAttachements(data)

            });
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/fomrationsadmin/user/${formation.id}/selected`;
            await fetchWithToken(apiUrl).then(data => {
                setUsers(data)
                setOpenListModal(true);
            });

        } catch (error) {
            console.error("Error listUserSelecing:", error);
        }

    };

    const handleOpenFormModal = (id) => {
        setKeyModal(keyModal + 1)
        setInitialFormData(id);
        setOpenFormModal(true);
    };

    const handleOpenListModal = (id) => {
        listUserSelecing();
    };
    const handleOpenQuestionnaireModal = (id) => {
        setSelectedFormationId(id);
        setOpenQuestionnaireModal(true);
    };
    const handleCloseQuestionnaireModal = () => {
        setOpenQuestionnaireModal(false);
        setSelectedFormationId(null);
    };
    const level = LevelOptions.find((branch) => formation.level === branch.key)
    const branch = branchOptions.find((branch) => formation.section_id === branch.id)
    const handleCloseFormModal = () => {
        if (hasUnsavedChangesFiche) {
            // Demander la confirmation si des modifications non sauvegardées sont présentes
            requestConfirm(t("MODIFICATION_LOST_WARNING"), () => {
                setOpenFormModal(false); // Fermer le modal si l'utilisateur confirme
            });
        } else {
            // Fermer le modal directement si aucune modification n'est détectée
            setOpenFormModal(false);
        }
        setHasUnsavedChangesFiche(false)
    };

    const handleCloseListModal = () => {
        // Fermer le modal directement si aucune modification n'est détectée
        setOpenListModal(false);
    };

    return (
        <>
            <Card className='cep-item' sx={{
                maxWidth: 345,
                margin: 2,
                textAlign: 'center',
                border: '2px solid #ccc',
                borderRadius: '10px',
                overflow: 'visible'
            }}>
                <CardMedia
                    component="img"
                    sx={{width: '60px', height: '60px', margin: '-25px auto 10px', zIndex: 1, position: 'relative'}}
                    image={branch.logo}

                />
                <CardContent>
                    <Typography id='cep-title' variant="h5" component="div">{level.label}</Typography>
                    <Typography sx={{fontFamily: 'Ubuntu', fontWeight: "bold", fontSize: "16px"}} variant="h6"
                                component="div">{formation.nom}</Typography>
                    <div id='cep-date'>
                        <div>du {formatDate(formation.start_date)}</div>
                        <div>au {formatDate(formation.end_date)}</div>
                    </div>

                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px 0'}}>
                        {statusIcons[formation.status].icon}<Typography variant="body1"
                                                                        sx={{marginLeft: 1}}>{statusIcons[formation.status].label}</Typography>
                    </Box>
                    {nbrStagiaire !== false && (
                        <div id='cep-people-count'>
                            {t('NB_STAGIAIRES_IN_THIS_FORM', nbrStagiaire)}
                        </div>
                    )}
                    <Box className='cep-action-button'>
                        <Button className='azimuth-btn' variant="contained" startIcon={<CircularIcon/>}
                                sx={{width: '100%'}}
                                onClick={handleOpenDocumentsModal}>{t("DOC_STAGIAIRE")}</Button>
                        <Button className='azimuth-btn' variant="contained" onClick={() => handleOpenListModal()}
                                startIcon={<ListIcon/>}
                                sx={{width: '100%'}}>{t("LIST_STAGIAIRE")}</Button>

                        {formation.questionaire_id != 0 && (
                            <>
                                <Button className='azimuth-btn' variant="contained"
                                        onClick={() => handleOpenFormModal(formation.questionaire_id)}
                                        startIcon={<QuestionnaireIcon/>}
                                        sx={{width: '100%'}}>{t("QUESTIONAIRE_STAGIAIRE")}</Button>

                                {formation.open_questionaire == 0 && (
                                    <Button className='azimuth-btn' variant="contained"
                                            onClick={() => handleOpenQuestionaire()}
                                            startIcon={<QuestionnaireIcon/>}
                                            sx={{width: '100%'}}>{t("QUESTIONAIRE_STAGIAIRE_OPEN")}</Button>
                                )}
                            </>
                        )}

                        {formation.questionaire_id == 0 && (
                            <Button className='azimuth-btn' variant="contained" onClick={() => handleOpenQuestionnaireModal(formation.id)}
                                    startIcon={<ListIcon />}
                                    sx={{ width: '100%' }}>{t('BUTTON_CHOOSE_FORM')}</Button>
                        )}
                    </Box>
                </CardContent>
            </Card>

            <Dialog open={openFormModal} onClose={handleCloseFormModal} maxWidth="lg" fullWidth>
                <DialogTitle>Modifier la Fiche</DialogTitle>
                <DialogContent>
                    <CustomFormBuilder key={keyModal} initialData={initialFormData} isModal={true}
                                       closeModal={handleCloseFormModal}
                                       setHasUnsavedChangesParent={setHasUnsavedChangesFiche}/>
                </DialogContent>
            </Dialog>

            <Dialog open={openListModal} onClose={handleCloseListModal} maxWidth="lg" fullWidth>
                <DialogContent>
                    <div className='stagiaire-card-container' container spacing={2}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>
                                <Button startIcon={<IosShareRounded/>} id='excel-export'
                                        onClick={() => handleExportExcel()} color="primary">
                                    Tout Exporter en Excel
                                </Button>
                                <Button startIcon={<IosShareRounded/>} id='excel-export'
                                        onClick={() => handleExportPDF()} color="primary">
                                    Tout Exporter en PDF
                                </Button>
                            </div>
                            <IconButton color='inherit' onClick={handleCloseListModal} sx={{height: 'fit-content'}}>
                                <CloseRounded/>
                            </IconButton>
                        </div>
                        {users && attachements && users.map((user, index) => (
                            <StagiareCard
                                key={index} user={user}
                                attachments={attachements.filter((attachement => attachement.formation_user_id === user.formation_user_id))}
                            />
                        ))}
                        {users.length === 0 && (
                            <Typography variant="body1" sx={{textAlign: 'center', width: '100%'}}>{t("NO_STAGIAIRE")}</Typography>
                        )}
                        <Button onClick={() => handleExportPDF()} color="primary">
                            Exporter toutes les fiches stagaires
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog maxWidth='md' fullWidth open={openDocumentsModal} onClose={handleCloseDocumentsModal}>
                <DialogTitle className='dialog-title'>Documents</DialogTitle>
                <DialogContent className='dialog-content'>
                    <div className='documents-container'>
                        {documents.map((doc) => (
                            <DocumentCard
                                document={doc}
                                onDelete={() => handleDeleteDocument(doc.id)}
                                onDownload={() => handleDownloadDocument(doc.path)}
                            />
                        ))}
                    </div>
                    <Box className='upload-document-container' sx={{ marginTop: 2 }}>
                        <input type="file" onChange={(e) => setSelectedFile(e.target.files[0])} />
                        <Button id='send-upload-document' variant="contained" startIcon={<CloudUploadIcon />} onClick={handleFileUpload}
                            disabled={!selectedFile}>
                            Upload
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <Dialog open={openQuestionnaireModal} onClose={handleCloseQuestionnaireModal}>
                <DialogTitle>{t("TITLE_SELECT_QUESTIONNAIRE")}</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <InputLabel>{t("LABEL_QUESTIONNAIRE")}</InputLabel>
                        <Select
                            value={selectedQuestionnaire}
                            label={t("LABEL_QUESTIONNAIRE")}
                            onChange={handleChangeQuestionnaire}
                            displayEmpty={false}
                        >
                            {questionnaires.map((questionnaire) => (
                                <MenuItem key={questionnaire.id} value={questionnaire.id}>
                                    {questionnaire.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseQuestionnaireModal} color="primary">
                        {t("BUTTON_CANCEL")}
                    </Button>
                    <Button onClick={() => handleSelectQuestionnaire(selectedFormationId, selectedQuestionnaire)}
                            color="primary">
                        {t("BUTTON_SELECT")}
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
};

export default FormationMangerItem;
