import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, CircularProgress } from "@mui/material";
import useTexts from "../hooks/useTexte";
import { useSystem } from "../Context/System";
import AgendaItem from "../composant/AgendaItem";
import './styles/Agenda.css';

const AgendaPage = () => {
    const [formations, setFormations] = useState([]);
    const [loading, setLoading] = useState(true);

    const { currentYear } = useSystem();
    const { t } = useTexts();

    useEffect(() => {
        // Récupérer les formations depuis l'API
        fetch(`${process.env.REACT_APP_API_URL}/api/pubformation`)
            .then(response => response.json())
            .then(data => {
                setFormations(data);
                setLoading(false);
            })
            .catch(error => {
                console.error("Erreur lors du chargement des formations :", error);
                setLoading(false);
            });
    }, []);

    // Fonction pour regrouper les formations par période
    const groupByPeriod = (data) => {
        return data.reduce((groups, formation) => {
            const period = formation.nom; // 'nom' est la période ici (été, noel, etc.)
            if (!groups[period]) {
                groups[period] = [];
            }
            groups[period].push(formation);
            return groups;
        }, {});
    };


    if (!currentYear){
        return "<></>";
    }
    // Regrouper les formations par période
    const groupedFormations = groupByPeriod(formations);
    const year = new Date(currentYear.start);

    return (
        <Container className='agenda-page-wrapper'>
            {loading ? (
                <Box display="flex" justifyContent="center" mt={5}>
                    <CircularProgress />
                </Box>
            ) : (
                <div className='agenda-container'>
                    {Object.keys(groupedFormations).map(period => (
                        <Box key={period} className='agenda-month-container'>
                            <Typography variant="h5" sx={{ fontWeight: 'bold', fontSize: '24px', color: '#3f51b5' }}>
                                {period} {year.getFullYear() + groupedFormations[period][0].year_cal}
                            </Typography>
                            <div className='agenda-events-container'>
                                {groupedFormations[period].map((formation, index) => (
                                    <AgendaItem formation={formation} index={index} />
                                ))}
                            </div>
                        </Box>
                    ))}
                </div>
            )}
        </Container>
    );
};

export default AgendaPage;
