import React, {useEffect, useState} from 'react';
import {
    Button,
    Typography,
    Alert,
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    Card,
    CardContent,
    Modal,
    Box,
    Select,
    MenuItem,
    Divider,
} from '@mui/material';
import {CalendarTodayRounded, Check, CheckCircleOutlineRounded, GroupsRounded} from '@mui/icons-material';
import {branchOptions} from '../../Utils/BranchOption';
import {LevelOptions} from '../../Utils/LevelOption';
import {formatErrorsArray} from "../../Utils/formatErrors";
import {calculateEndDate, formatDate, transformFunctionOption} from '../../Utils/functionTransforms';
import {ValidateButton} from "../../Utils/ValidateButton";
import {useFetchWithToken} from "../../hooks/useFetchWithToken";
import useTexts from "../../hooks/useTexte";
import AnimateButton from "../Core/AnimateButton";
import {useAuth} from "../../Context/AuthContext";

import './styles/WishListTable.css';

const WishListTable = ({wish, onDelete, index, type, realodWish, close}) => {
    const fetchWithToken = useFetchWithToken();
    const [formationData, setFormationData] = useState([]);
    const [loadFormationData, setLoadFormationData] = useState(true);
    const [isRequireValidate, setIsRequireValidate] = useState(false);
    const [openValidation, setOpenValidation] = useState(false);
    const [wishValidation, setWishValidation] = useState(0);
    const [formation, setFormation] = useState(0);
    const {functionOptions} = useAuth();
    const [openChangeFunction, setOpenChangeFunction] = useState(false);
    const [saveFunction, setSaveFunction] = useState(false);
    const {t} = useTexts();

    const handleChangeFunctionOpen = () => {
        setSaveFunction(wish.function_id);
        setOpenChangeFunction(true);
    };

    const handleChangeFunctionClose = () => setOpenChangeFunction(false);

    const renderFormationStatus = () => {
        if (wish.valide) {
            if (!formationData.length) {
                return (
                    <Typography
                        sx={{fontFamily: "ubuntu"}}
                        variant="body1"
                        className={"noformation"}
                    >
                        {t("TEXT_NO_FORMATION")}
                    </Typography>
                );
            }
            if (!isRequireValidate) {
                return formationData.map((formation, idx) => {
                    let text = "";
                    if (formation.status === "open") {
                        text = t("WAIT_ATTRIBUTION");
                    } else if (formation.status === "selecting") {
                        text = t("WAIT_LIST_FORMATION_RUN");
                    } else if (formation.status === "selected") {
                        text = t("WAIT_LIST_FORMATION");
                    }
                    return (
                        <div key={idx} className={""} style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '4px',
                        }}>
                            <Typography
                                sx={{fontFamily: "ubuntu"}}
                                variant="body1"
                                className={""}
                            >
                                {formation.nom}
                            </Typography>
                            <Typography
                                sx={{fontFamily: "ubuntu"}}
                                variant="body1"
                                className={"inwaitlist waitrun"}
                            >
                                {text}
                            </Typography>
                        </div>
                    );
                });
            } else {
                let userHasValide = false;
                formationData.map((formation) => {
                    if (formation.statusValidation) {
                        if (formation.statusValidation.find(f => f.role === 1)) {
                            userHasValide = true;
                        }
                    }
                });

                if (!userHasValide) {
                    return (
                        <Typography
                            sx={{
                                display: {xs: 'block', sm: 'flex'},
                                fontFamily: "ubuntu",
                                alignItems: 'center',
                                gap: 1
                            }}
                        >
                            <span
                                style={{
                                    color: 'green',
                                    marginRight: '4px',
                                    alignItems: 'center',
                                    borderRadius: '5px',
                                    display: 'flex',
                                    gap: '2px'
                                }}
                            >
                                <CheckCircleOutlineRounded color="success"/> Place attribuée
                            </span>
                            <ValidateButton
                                onClick={() => {
                                    setWishValidation(wish);
                                    setOpenValidation(true);
                                }}
                                startIcon={<Check/>}
                                className="unique-validate-button"
                            >
                                Valider l'inscription
                            </ValidateButton>
                        </Typography>
                    );
                } else {
                    return (
                        <Typography
                            sx={{fontFamily: "ubuntu"}}
                            variant="body1"
                            className={"noformation"}
                        >
                            {t("WAIT_CHEF_VALIDATION")}
                        </Typography>
                    );
                }
            }
        } else {
            return (
                <>
                    <Typography
                        sx={{fontFamily: "ubuntu", fontWeight: 'bold'}}
                        variant="body1"
                        className="requirevalidation"
                        dangerouslySetInnerHTML={{__html: t("TEXT_INSCRIPTON_NEED_DEROGATION")}}
                    />
                    {wish.invalide_data && wish.invalide_data.length > 0 && (
                        <ul>
                            {formatErrorsArray(wish.invalide_data, t).map((error, index) => (
                                <li key={index}>{error}</li>
                            ))}
                        </ul>
                    )}
                </>
            );
        }
    };

    const onValidate = async () => {
        try {
            const body = {
                formation_user_id: formation.statusInscription.id,
            };
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formation/validate`;
            await fetchWithToken(apiUrl, "POST", body);
            setOpenValidation(false);
            loadWishesData();
            realodWish();
        } catch (error) {
            console.log(error);
        }
    };

    const loadWishesData = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/wishes/${wish.id}/data`;
            const data = await fetchWithToken(apiUrl);
            setFormationData(data.formations);
            data.formations.map((formation) => {
                if (formation.statusInscription) {
                    if (formation.statusInscription.status === 'WAIT_VALIDATION') {
                        setIsRequireValidate(true);
                        setFormation(formation);
                    }
                }
            });
        } catch (error) {
            console.log(error);
        }
        setLoadFormationData(false);
    };

    useEffect(() => {
        setOpenChangeFunction(false);
        loadWishesData();
    }, []);

    const handleUpdateFunction = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/wishes/${wish.id}/updateFunction/${saveFunction}`;
            await fetchWithToken(apiUrl, "POST");
            setOpenChangeFunction(false);
            realodWish();
        } catch (error) {
            console.error('Erreur lors :', error);
        }
    };

    const level = LevelOptions.find((branch) => wish.level === branch.key);
    const chefFunction = functionOptions.find(func => func.id === wish.function_id);
    const branch = branchOptions.find((branch) => wish.branch === branch.key);

    return (
        <div className='wish-card-container'>
            <Dialog open={openValidation} onClose={() => setOpenValidation(false)}>
                <DialogTitle>{t("TEXT_VALIDER_PLACE_FORMATION")}</DialogTitle>
                <DialogContent>
                    <Typography>{t("TEXT_YOU_HAVE_TAKE_TO_FORMATION")}</Typography>
                    <ul>
                        <li>{t("TEXT_COL_BRANCHE")} : {wishValidation.branch}</li>
                        <li>{t("TEXT_COL_TYPE")} : {wishValidation.level}</li>
                        <li>{t("TEXT_COL_DATE")} : {formatDate(wishValidation.date)} au {calculateEndDate(wishValidation.date, branch.key,
                            level.key
                        )}</li>
                    </ul>
                    <Alert severity="warning">
                        {t("TEXT_WARNING_VALIDATION")}
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => setOpenValidation(false)}>
                        {t("BUTTON_ANNULER")}
                    </Button>
                    <Button color="primary" onClick={() => onValidate()}>
                        {t("BUTTON_VALIDER")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Card className='wish-card'>
                <CardContent className='wish-card-content'>

                    <div className='wish-card-header'>
                        <div style={{flex: '0 0 30px', marginBottom: {xs: 2, sm: 0}}}>
                            <img src={branch.logo} alt="Training" style={{width: '50px', height: '50px'}}/>
                        </div>
                        <div
                            style={{flex: '1 1 20%', marginBottom: {xs: 2, sm: 0}}}
                            className={type !== "delete" ? "headlineLevel" : ""}
                        >
                            {level.label}
                        </div>
                        {type !== "delete" && close == 0 && (
                            <div
                                style={{
                                    flex: {xs: 'unset', sm: '0 0 5%'},
                                    marginBottom: {xs: 2, sm: 0},
                                    marginLeft: {xs: 'unset', sm: 'auto'}
                                }}
                                onClick={() => onDelete(wish.voeu_id)}
                            >
                                <AnimateButton type={"delete"}/>
                            </div>
                        )}
                    </div>
                    <Divider/>
                    {close == 0 && (
                        <div className='wish-content-function' style={{flex: '1 1 20%', marginBottom: {xs: 2, sm: 0}}}>
                            <div id='wish-content-item-left'>
                                <GroupsRounded/>
                                {chefFunction ? (
                                    transformFunctionOption(chefFunction)
                                ) : (
                                    <div>{t("INVALIDE_FUNCTION_NEED_ACTION")}</div>
                                )}
                            </div>
                            <Button id='function-change' variant="outlined" onClick={handleChangeFunctionOpen}
                                    sx={{marginTop: 1}}>
                                {t("FUNCTION_WISH_CHANGE")}
                            </Button>
                        </div>
                    )}
                    <Divider id='mobile-divider'/>
                    <div className='wish-content-date' style={{flex: '1 1 20%', marginBottom: {xs: 2, sm: 0}}}>
                        <CalendarTodayRounded/>
                        <div id='date-from-to'>
                            <p>du <span id='chip'>{formatDate(wish.date)}</span></p>
                            <p>au <span id='chip'>{calculateEndDate(wish.date, branch.key, level.key)}</span></p>
                        </div>
                    </div>
                    <Divider id='mobile-divider'/>


                    {close == 0 ? (
                        <>
                            {type !== "delete" && (
                                <div className='wish-formation-status'>
                                    {renderFormationStatus()}
                                </div>
                            )}
                        </>
                    ) : (
                        <div className='wish-formation-status'>
                            {t("VOEU_CLOSE")}
                        </div>
                    )}


                </CardContent>
            </Card>
            <Modal
                open={openChangeFunction}
                onClose={handleChangeFunctionClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4
                    }}
                >
                    <h2 id="modal-modal-title">{t("FUNCTION_WISH_CHANGE")}</h2>
                    <p id="modal-modal-description">{t("FUNCTION_WISH_CHANGE_TEXT")}</p>
                    {functionOptions && functionOptions.length >= 1 && (
                        <Select
                            value={saveFunction}
                            displayEmpty
                            onChange={(event) => setSaveFunction(event.target.value)}
                            fullWidth
                        >
                            {functionOptions.map(({fonction, id, nom, type}) => (
                                <MenuItem key={id} value={id}>
                                    {nom} - {fonction}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                    <Button onClick={handleChangeFunctionClose} variant="contained">
                        {t("BUTTON_FERMER")}
                    </Button>
                    <Button onClick={handleUpdateFunction} variant="contained">
                        {t("VALIDATE_FUNCTION_BTN")}
                    </Button>
                </Box>
            </Modal>
        </div>
    );
};

export default WishListTable;
